/* eslint-disable no-bitwise */
import { permissionSelector } from 'features/auth/reducer/auth';
import { useCallback } from 'react';
import { useAppSelector } from 'store';

export interface PermissionStruct {
  value: number;
  module: string;
}

export const usePermission = () => {
  const permissions = useAppSelector(permissionSelector);

  const hasPermission = useCallback(
    (...permissionArgs: PermissionStruct[]): boolean => {
      if (!permissions) return false;
      return permissionArgs.every((permission) => {
        const value = permissions[permission.module];
        return value ? (value & permission.value) !== 0 : false;
      });
    },
    [permissions],
  );

  return { hasPermission };
};
