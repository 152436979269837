import { Button } from 'components/KendoReact';
import { ReactNode } from 'react';

import styles from './TabTitle.module.scss';

interface TabTitleProps {
  title: ReactNode;
  mainTab?: boolean;
  id: string;
  icon?: string;
  onTabRemove?: ((value: string) => void) | null;
}

const TabTitle = ({ title, id, icon, mainTab = false, onTabRemove = null }: TabTitleProps) => {
  const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button === 1 && onTabRemove) {
      onTabRemove(id);
    }
  };
  return (
    <div tabIndex={0} role="button" onMouseDown={mouseDownHandler} className={styles.titleContainer}>
      {icon && <img src={icon} alt="tab-icon" />}
      <span className={mainTab ? styles.mainTitle : styles.title}>{title}</span>
      {onTabRemove && (
        <Button
          fillMode="flat"
          onClick={(e) => {
            e.stopPropagation();
            onTabRemove(id);
          }}
          icon="x"
        />
      )}
    </div>
  );
};

export default TabTitle;

TabTitle.defaultProps = {
  icon: '',
  onTabRemove: null,
  mainTab: false,
};
