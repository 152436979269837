import api from 'services/api';

import { OperatorDTO } from '../types';

const BASE_URL = 'configuration/operators';

export const getOperators = async () => {
  const { data } = await api.get<OperatorDTO[]>(`${BASE_URL}`);
  return data;
};

export const getOperator = async (id: number) => {
  const { data } = await api.get<OperatorDTO>(`${BASE_URL}/${id}`);
  return data;
};

export const deleteOperator = async (id: number) => {
  await api.delete<boolean>(`${BASE_URL}/${id}`);
};

export const saveOperator = async (operator: OperatorDTO) => {
  await api.post<OperatorDTO>(`${BASE_URL}`, operator);
};
