import { useFocusedTab } from 'hooks/useFocusedTab';
import { HtmlHTMLAttributes, useRef } from 'react';

export const RenderWhenTabFocused = ({ children, ...rest }: HtmlHTMLAttributes<HTMLDivElement>) => {
  const ref = useRef<HTMLDivElement>(null);
  const isFocused = useFocusedTab(ref);

  return (
    <div {...rest} ref={ref}>
      {isFocused && children}
    </div>
  );
};
