import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

import { ITagInfo } from '../types';

const initialState = {
  value: {} as ITagInfo,
};

export const tagsList = createSlice({
  name: 'tag-info',
  initialState,
  reducers: {
    selectTag: (state, action: PayloadAction<ITagInfo>) => {
      state.value = action.payload;
    },
  },
});

export const { selectTag } = tagsList.actions;

export const tagSelector = (state: RootState) => state.tagInfo;

export default tagsList.reducer;
