import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ReactNode, RefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AddItemDialogProps {
  title?: string;
  visible: boolean;
  dialogClassName?: string;
  handleClose: () => void;
  renderForm: (ref: RefObject<AddItemDialogRef>) => ReactNode;
}

export type AddItemDialogRef = {
  saveChanges: () => Promise<void>;
};

const AddItemDialog = ({ visible, handleClose, renderForm, title, dialogClassName }: AddItemDialogProps) => {
  const { t } = useTranslation(['common']);
  const [isLoading, setIsLoading] = useState(false);
  const dialogRef = useRef<AddItemDialogRef>(null);

  const renderHeader = useMemo(() => <span>{title ?? t('common:create')}</span>, [t, title]);

  const handleSaveButton = async () => {
    if (dialogRef.current) {
      try {
        setIsLoading(true);
        await dialogRef.current.saveChanges();
        handleClose();
      } catch (error) {
        toast.error(`${error}`, {
          toastId: 'error-saving-item',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const dialogFooter = (
    <div>
      <Button
        id="save-user-types"
        type="button"
        className="capitalize"
        label={t('common:save')}
        loading={isLoading}
        icon="pi pi-check"
        onClick={handleSaveButton}
      />
      <Button
        id="cancel-save-user-types"
        type="button"
        label={t('common:cancel')}
        icon="pi pi-times"
        className="p-button-outlined capitalize"
        onClick={handleClose}
      />
    </div>
  );
  return (
    <Dialog
      visible={visible}
      className={dialogClassName}
      header={renderHeader}
      footer={dialogFooter}
      onHide={handleClose}
      modal
    >
      {renderForm(dialogRef)}
    </Dialog>
  );
};

AddItemDialog.defaultProps = {
  title: undefined,
  dialogClassName: undefined,
};

export default AddItemDialog;
