import searchIcon from 'assets/images/icons/search.png';
import { Button, Input } from 'components/KendoReact';
import { Dialog } from 'components/PrimeReact';
import { Column } from 'primereact/column';
import { DataTable, DataTableSelectionChangeEvent } from 'primereact/datatable';
import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ItemPickerDialog.module.scss';

export type SelectionMode = 'single' | 'multiple';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ItemPickerDialogProps<T extends Record<string, any>, Y extends SelectionMode> = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  values: T[];
  isLoading: boolean;
  dataKey: keyof T & string;
  selectionMode: Y;
  children: ReactNode;
  onAddAsync: (values: T[]) => Promise<void>;
  width?: string;
  onSearch?: (value: string) => void;
  searchMessageParams?: SearchMessageParameters;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ItemPickerDialog = <T extends Record<string, any>, Y extends SelectionMode>({
  visible,
  setVisible,
  dataKey,
  values,
  isLoading,
  children,
  selectionMode,
  onAddAsync,
  width,
  onSearch,
  searchMessageParams,
}: ItemPickerDialogProps<T, Y>) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Array<T>>([]);

  const handleAdd = useCallback(async () => {
    if (selected) {
      await onAddAsync(selected);
      setVisible(false);
      setSelected([]);
    }
  }, [onAddAsync, selected, setVisible]);

  const handleClose = useCallback(() => setVisible(false), [setVisible]);

  const handleChangeSelection = useCallback((e: DataTableSelectionChangeEvent<T[]>) => setSelected(e.value as T[]), []);

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button themeColor="primary" loading={isLoading} disabled={!selected} icon="check" onClick={handleAdd}>
        {t('common:add')}
      </Button>
      <Button onClick={handleClose}>{t('common:cancel')}</Button>
    </div>
  );

  const renderHeader = useCallback(
    () => (
      <div className={styles.headerContainer}>
        <span>{t('common:add-items')}</span>
      </div>
    ),
    [t],
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: width || '' }}
      className={styles.dialog}
      header={renderHeader}
      modal
      footer={itemDialogFooter}
      onHide={handleClose}
    >
      {onSearch && (
        <div className={styles.search}>
          <Input
            className={styles.searchInput}
            type="search"
            maxLength={50}
            placeholder={t('common:search')}
            onChange={(e) => onSearch(e.value)}
          />
          <img className={styles.searchIcon} src={searchIcon} alt="search" />
        </div>
      )}

      {onSearch && searchMessageParams && (
        <label className={styles.searchMessage}>
          {t('common:picker-showing-items-from-total', {
            numItems: searchMessageParams.items,
            itemName: searchMessageParams.itemName,
            totalItems: searchMessageParams.total,
          })}
        </label>
      )}

      <DataTable
        value={values}
        dataKey={dataKey as string}
        loading={isLoading}
        selectionMode={selectionMode}
        onSelectionChange={handleChangeSelection}
        selection={selected}
        rows={10}
      >
        <Column selectionMode={selectionMode} headerStyle={{ width: '3em' }} />
        {children as JSX.Element}
      </DataTable>
    </Dialog>
  );
};

ItemPickerDialog.defaultProps = {
  width: undefined,
  onSearch: undefined,
  searchMessageParams: undefined,
};

export interface SearchMessageParameters {
  items: number;
  itemName: string;
  total: number;
}

export default ItemPickerDialog;
