import type { AppStatus, MetrisApp } from 'features/apps.interface';
import { useTranslation } from 'react-i18next';

import styles from './AppCard.module.scss';

type CardAppProps = {
  app: MetrisApp;
};

const getProductColor = (status: AppStatus): 'orange' | 'blue' | 'green' => {
  switch (status) {
    case 'Development':
      return 'orange';
    case 'Preview':
      return 'blue';
    case 'Productize':
      return 'green';
    default:
      return 'blue';
  }
};

const AppCard = ({ app }: CardAppProps) => {
  const color = getProductColor(app.status);
  const showStatus = app.status !== 'Released';
  const { t } = useTranslation('common');

  const handleCardClick = () => {
    window?.open(`#/app/${app.path}`, app.name(t))?.focus();
  };

  return (
    <div
      role="button"
      tabIndex={app.id}
      onKeyDown={handleCardClick}
      onClick={handleCardClick}
      className={`${styles.appCard} ${styles[color]}`}
    >
      <div className={styles.cardHeader}>
        {showStatus && <div className={`${styles.statusRibbon} ${styles[color]}`}>{app.status}</div>}
        <img src={`${process.env.PUBLIC_URL}/${app.image}`} alt={app.name(t)} />
      </div>
      <div className={styles.cardBody}>
        <span>
          <b>{app.name(t)}</b>
        </span>
      </div>
    </div>
  );
};

export default AppCard;
