import { Key, createContext } from 'react';

export interface ContextProps {
  zoomKey: Key | null;
  setZoomKey: (value: Key | null) => void;
}

export const ExpandableContext = createContext<ContextProps>({
  zoomKey: null,
  setZoomKey: () => {},
});
