import i18next from 'i18next';
import { toast } from 'react-toastify';
import api from 'services/api';

import { User, UserWithAvatar } from '../types';

const BASE_URL = 'configuration';

export const getManagerUsers = async () => {
  const { data } = await api.get<User[]>(`${BASE_URL}/manager/users`);

  return data.map(({ type, ...rest }) => ({ type: type ?? 0, ...rest }));
};

export const getUser = async (idUser: number) => {
  const { data } = await api.get(`${BASE_URL}/users/${idUser}`);
  return data as User;
};

export const getUserAvatar = async (idUser: number) => {
  const { data } = await api.get(`${BASE_URL}/users/${idUser}/webavatar`);
  return data as string;
};

export const getUsersWithAvatar = async () => {
  try {
    const { data } = await api.get(`${BASE_URL}/userwithavatar`);
    return data as UserWithAvatar[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(i18next.t<string>('common:errors.failed-to-get-users', { message: e.message }));
    throw e;
  }
};

export const DisableUser = async (idUser: number) => {
  const { data } = await api.post<boolean>(`${BASE_URL}/users/${idUser}/disable`);
  return data;
};

export const SaveUser = async (user: User) => {
  const userDTO = {
    id: user.id,
    name: user.name,
    email: user.email,
  };

  const { data } = await api.post<User>(`${BASE_URL}/users`, userDTO);
  return data;
};
