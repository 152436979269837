import i18next from 'i18next';
import { toast } from 'react-toastify';
import api from 'services/api';
import { handleException } from 'utils/exception';

import {
  CredentialsUpdate,
  CredentialsUpdateResult,
  LoginRequest,
  LoginResponse,
  MultiFactorAuthenticationResult,
  SignInCredential,
  SignInIdentificationType,
  User,
  UserGroup,
  UserPermission,
  UserToken,
} from '../types';

const BASE_URL = 'account';

export const loginWithEmailAndPassword = async (request: LoginRequest) => {
  try {
    const { data } = await api.post<UserToken>(`${BASE_URL}/authenticate`, request);
    return data;
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('auth:authentication-error'), undefined, false);
    throw e;
  }
};

export const loginByToken = (token: string): Promise<LoginResponse> =>
  api.post(`${BASE_URL}/validate-exchange-token`, { token });

export const getUser = async (idUser: number): Promise<User> => {
  const { data } = await api.get(`/configuration/users/${idUser}`);
  return data as User;
};

export const getUserPermission = async (): Promise<UserPermission> => {
  const { data } = await api.get(`/configuration/currentuser/permission`);
  return data as UserPermission;
};

export const generateCode = async (email: string) => {
  try {
    await api.post(`${BASE_URL}/code/generate`, { email, type: SignInIdentificationType.ForgotPassword });
    toast.success(i18next.t<string>('auth:the-code-was-generated-with-success-please-check-your-email'));
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('auth:failed-to-generate-code'));
  }
};

export const confirmCode = async (email: string, code: string) => {
  try {
    await api.post(`${BASE_URL}/code/confirm`, { email, code, type: SignInIdentificationType.ForgotPassword });
    toast.success(i18next.t<string>('auth:the-code-was-confirmed'));
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('auth:failed-to-confirm-code'));
  }
};

export const getCredentials = async (email: string, code: string) => {
  const { data } = await api.post<SignInCredential>(`${BASE_URL}/credential/get`, {
    email,
    code,
    type: SignInIdentificationType.ForgotPassword,
  });
  return data;
};

export const changePassword = async (email: string, code: string, password: string) => {
  try {
    const credentials = await getCredentials(email, code);
    await api.post(`${BASE_URL}/credential/change`, { ...credentials, password });
    toast.success(i18next.t<string>('auth:the-password-was-updated-successfully'));
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('auth:failed-to-update-password'));
  }
};

export const forceChangePassword = async (username: string, credentials: CredentialsUpdate) => {
  try {
    const { data } = await api.post<CredentialsUpdateResult>(`${BASE_URL}/credential/update/${username}`, credentials);
    if (data === CredentialsUpdateResult.InvalidPassword) {
      throw new Error(i18next.t<string>('auth:failed-to-update-password'));
    }
    toast.success(i18next.t<string>('auth:the-password-was-updated-successfully-please-log-in-again'), {
      autoClose: false,
    });
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('auth:failed-to-update-password'));
  }
};

export const getMultiFactorAuthentication = async () => {
  const { data } = await api.post<string>(`system/specificenvironmentvariables`, 'MultiFactorAuthenticationEnable');
  return /true/.test(data);
};

export const getUserGroup = async (idUserGroup: number) => {
  const { data } = await api.get<UserGroup>(`configuration/usergroups/${idUserGroup}`);
  return data;
};

export const postMultiFactorAuthenticationCode = async (code: string) => {
  try {
    const { data } = await api.post<MultiFactorAuthenticationResult>(
      `account/MultiFactorAuthenticationCode/`,
      `${code}`,
    );
    switch (data) {
      case MultiFactorAuthenticationResult.Valid:
        return data;
      case MultiFactorAuthenticationResult.Invalid:
        throw new Error(i18next.t<string>('auth:return-invalid-mfa'));
      case MultiFactorAuthenticationResult.Timeout:
        throw new Error(i18next.t<string>('auth:return-timeout-mfa'));
      case MultiFactorAuthenticationResult.UserNotFound:
        throw new Error(i18next.t<string>('auth:return-userNotFound-mfa'));
      default:
        throw new Error(i18next.t<string>('auth:return-invalid-mfa'));
    }
  } catch (error: any) {
    toast.error(error?.message);
    throw error;
  }
};
