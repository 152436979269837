import { Route, Routes } from 'react-router-dom';

import { ForgotPasswordPage, LoginPage } from '../pages';

export const AuthRoutes = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/reset-password" element={<ForgotPasswordPage />} />
  </Routes>
);
