import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Host, Service } from 'domain/spm/types';
import type { RootState } from 'store/rootReducer';

interface SPMState {
  visibleHost: Host | null;
  visibleService: Service | null;
}

const initialState: SPMState = {
  visibleHost: null,
  visibleService: null,
};

const slice = createSlice({
  name: 'spm',
  initialState,
  reducers: {
    selectVisibleHost(state, action: PayloadAction<Host>) {
      if (state.visibleHost?.id === action.payload.id) return;
      state.visibleHost = action.payload;
      if (state.visibleService && !state.visibleHost.services.includes(state.visibleService)) {
        state.visibleService = null;
      }
    },
    unselectVisibleHost(state) {
      state.visibleHost = null;
      state.visibleService = null;
    },
    selectVisibleService(state, action: PayloadAction<{ host: Host; service: Service }>) {
      if (state.visibleService?.id === action.payload.service.id) return;
      state.visibleHost = action.payload.host;
      state.visibleService = action.payload.service;
    },
    unselectVisibleService(state) {
      state.visibleService = null;
    },
  },
});

export const { selectVisibleHost, unselectVisibleHost, selectVisibleService, unselectVisibleService } = slice.actions;

export const visibleHostSelector = (state: RootState) => state.spm.visibleHost;
export const visibleServiceSelector = (state: RootState) => state.spm.visibleService;

export default slice.reducer;
