import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Sensor, SortableEquipment } from 'domain/cms/types';
import type { RootState } from 'store/rootReducer';

// Define a type for the slice state
interface SortableEquipmentsState {
  equipments: SortableEquipment[];
  selectedSensor: Sensor | null;
  hasResettedAlarm: boolean;
}

// Define the initial state using that type
const initialState: SortableEquipmentsState = {
  equipments: [],
  selectedSensor: null,
  hasResettedAlarm: false,
};

export const sortableEquipmentsSlice = createSlice({
  name: 'sortable_equipments',
  initialState,
  reducers: {
    setSelectedSensor: (state, action: PayloadAction<Sensor | null>) => {
      state.selectedSensor = action.payload;
    },
    setEquipments: (state, action: PayloadAction<SortableEquipment[]>) => {
      state.equipments = action.payload;
    },
    resetSensorAlarm: (state) => {
      const { selectedSensor } = state;
      if (selectedSensor === null || selectedSensor.isAlarmed) {
        return;
      }

      const foundEquipments = state.equipments.filter(
        (e) => e.vibrationSensors.some((s) => s.id === selectedSensor.id && s.isAlarmed),
        // eslint-disable-next-line function-paren-newline
      );

      if (foundEquipments.length === 0) {
        return;
      }

      const equipment = foundEquipments[0];
      const foundSensors = equipment.vibrationSensors.filter(
        (sensor) => sensor.id === selectedSensor.id && sensor.isAlarmed,
      );
      const sensor = foundSensors[0];

      sensor.isAlarmed = false;
      state.hasResettedAlarm = true;

      equipment.isAlarmed = equipment.vibrationSensors.some((s) => s.isAlarmed);
    },
    setHasResettedAlarm: (state, action: PayloadAction<boolean>) => {
      state.hasResettedAlarm = action.payload;
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const { setSelectedSensor, resetSensorAlarm, setEquipments, setHasResettedAlarm } =
  sortableEquipmentsSlice.actions;

export const selectedSensorSelector = (state: RootState) => state.cms.selectedSensor;
export const equipmentsSelector = (state: RootState) => state.cms.equipments;
export const hasResettedAlarmSelector = (state: RootState) => state.cms.hasResettedAlarm;

export default sortableEquipmentsSlice.reducer;
