import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

import { Language, Theme } from '../types';

export const supportedLanguages: Language[] = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fi',
  'fr',
  'hi',
  'hu',
  'id',
  'it',
  'ja',
  'ms',
  'nl',
  'pl',
  'pt-BR',
  'pt-PT',
  'ru',
  'sv',
  'zh',
];

type SettingsState = {
  theme: Theme;
  language: Language;
};

const getUserLanguage = (): Language => {
  const browserLanguage =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : window.navigator.language;

  const languageCode = browserLanguage.split('-')[0] as Language;

  if (supportedLanguages.includes(languageCode)) {
    return languageCode;
  }
  return 'en';
};

const initialState: SettingsState = {
  theme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  language: getUserLanguage(),
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark';
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
  },
});

export const { toggleTheme, setLanguage, setTheme } = settingsSlice.actions;

export const themeSelector = (state: RootState) => state.settings.theme;
export const languageSelector = (state: RootState) => state.settings.language || 'en';

export default settingsSlice.reducer;
