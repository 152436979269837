import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import authReducer, { logout } from 'features/auth/reducer/auth';
import tagInfoReducer from 'features/autonomousOperations/reducers/tagInfo';
import tagViewReducer from 'features/autonomousOperations/reducers/tagView';
import sortableEquipmentsReducer from 'features/cms/reducers/equipments';
import tagsListReducer from 'features/dataAnalytics/reducers/tagsList';
import treeViewTagListsReducer from 'features/dataAnalytics/reducers/treeViewTagLists';
import settingsReducer from 'features/main/reducers/settings';
import spmReducer from 'features/spm/reducers';
import workPermitReducer from 'features/workPermit/reducers/workPermit';

const appReducer = combineReducers({
  auth: authReducer,
  cms: sortableEquipmentsReducer,
  tagsList: tagsListReducer,
  tagInfo: tagInfoReducer,
  tagView: tagViewReducer,
  settings: settingsReducer,
  treeViewTagLists: treeViewTagListsReducer,
  workPermit: workPermitReducer,
  spm: spmReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logout.toString()) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
