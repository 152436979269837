import { MutableRefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useClickOutside = (ref: MutableRefObject<any>, buttonRef: MutableRefObject<any>, callback: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !buttonRef.current.contains(event.target) &&
        // this avoids calling the callback function when clicking on a dropdown, otherwise the popup would be closed when clicking on a dropdown
        !(event.target as Element).className.includes('p-dropdown')
      ) {
        callback();
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef, callback, ref]);
};
