/* eslint-disable no-nested-ternary */
import { useMediaQuery } from 'hooks';
import { Key, ReactElement, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import styles from './ExpandableContainer.module.scss';
import { ExpandableContext } from './ExpandableContext';

interface ExpandableContainerProps {
  children: ReactElement[];
}

export interface ExpandableContainerRef {
  expanded: boolean;
}

const ExpandableContainer = forwardRef<ExpandableContainerRef, ExpandableContainerProps>(
  ({ children }: ExpandableContainerProps, ref) => {
    const [zoomKey, setZoomKey] = useState<Key | null>(null);
    const { isMobile, isTablet } = useMediaQuery();

    const values = useMemo(
      () => ({
        zoomKey,
        setZoomKey,
      }),
      [zoomKey],
    );

    useImperativeHandle(
      ref,
      () => ({
        expanded: zoomKey != null,
      }),
      [zoomKey],
    );

    useEffect(() => {
      if (isMobile && zoomKey != null) setZoomKey(null);
    }, [isMobile, zoomKey]);

    const list = zoomKey ? children.filter((child) => child.key !== zoomKey) : children;
    const zoom = children.find((child) => child.key === zoomKey);

    return (
      <ExpandableContext.Provider value={values}>
        {isTablet ? (
          <div className={styles.zoomContainerTablet}>
            <div className={styles.cardZoomTablet}>{zoom}</div>
            <div className={zoomKey ? styles.cardListTablet : styles.linearContainer}>{list}</div>
          </div>
        ) : zoomKey ? (
          <div className={styles.zoomContainer}>
            <div className={styles.cardZoom}>{zoom}</div>
            <div className={styles.cardList}>{list}</div>
          </div>
        ) : (
          <div className={styles.linearContainer}>{list}</div>
        )}
      </ExpandableContext.Provider>
    );
  },
);

export default ExpandableContainer;
