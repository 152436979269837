/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTimePicker } from 'components/KendoReact';
import { ColumnBodyOptions, ColumnProps, Column as PrimeColumn } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { ReactNode } from 'react';

import styles from './utils.module.scss';

interface BaseInputProps<T, R> {
  props: any;
  field: keyof T;
  onEditorValueChange: (props: any, value: R) => void;
}

interface InputTextEditorProps<T> extends BaseInputProps<T, string> {
  rows?: number;
  value?: string | null;
}

interface CustomColumnProps<T> extends ColumnProps {
  field?: keyof T & string;
  body?: React.ReactNode | ((data: T, options: ColumnBodyOptions) => React.ReactNode);
}

export const Column = <T,>({ field, ...props }: CustomColumnProps<T>) => (
  <PrimeColumn field={field as string} {...props} />
);

Column.defaultProps = {
  field: undefined,
  body: undefined,
};

export const inputTextEditor = <T,>({
  props,
  field,
  onEditorValueChange,
  rows = 1,
  value = null,
}: InputTextEditorProps<T>) => (
  <InputTextarea
    className={styles.inputTextEditor}
    value={value ?? props.rowData[field] ?? ''}
    rows={rows}
    autoResize
    autoFocus
    onChange={(e) => onEditorValueChange(props, e.target.value)}
  />
);

interface InputNumberEditorProps<T> extends BaseInputProps<T, number> {
  minFractionDigits?: number;
}

export const inputNumberEditor = <T,>({
  props,
  field,
  onEditorValueChange,
  minFractionDigits = 2,
}: InputNumberEditorProps<T>) => (
  <InputNumber
    className={styles.inputNumberEditor}
    value={props.rowData[field] ?? 0}
    onChange={(e) => onEditorValueChange(props, e.value ?? 0)}
    mode="decimal"
    minFractionDigits={minFractionDigits}
  />
);

interface DateTimePickerEditorProps<T> extends BaseInputProps<T, Date | null> {
  min?: Date;
  max?: Date;
}

export const dateTimePickerEditor = <T,>({
  props,
  field,
  onEditorValueChange,
  min,
  max,
}: DateTimePickerEditorProps<T>) => (
  <DateTimePicker
    value={new Date(props.rowData[field])}
    onChange={(e) => onEditorValueChange(props, e.value)}
    min={min}
    max={max}
  />
);

export const inputBooleanEditor = <T,>({ props, field, onEditorValueChange }: BaseInputProps<T, boolean>) => (
  <InputSwitch checked={props.rowData[field] ?? false} onChange={(e) => onEditorValueChange(props, e.value ?? false)} />
);

interface InputDropdownEditorProps<T, R> extends BaseInputProps<T, R> {
  optionLabel: keyof R;
  dataKey: keyof R;
  options: R[];
  showClear?: boolean;
  filter?: boolean;
  placeholder?: string;
  itemTemplate?: ReactNode;
  valueTemplate?: ReactNode;
}

export const inputDropdownEditor = <T, R>({
  props,
  field,
  onEditorValueChange,
  optionLabel,
  options,
  itemTemplate,
  valueTemplate,
  dataKey,
  showClear = false,
  filter = false,
  placeholder = 'Select an option',
}: InputDropdownEditorProps<T, R>) => (
  <Dropdown
    value={props.rowData[field]}
    dataKey={dataKey as string}
    options={options}
    onChange={(e) => onEditorValueChange(props, e.value)}
    optionLabel={optionLabel as string}
    filter={filter}
    showClear={showClear}
    filterBy={optionLabel as string}
    placeholder={placeholder}
    valueTemplate={valueTemplate}
    itemTemplate={itemTemplate}
  />
);

export const filterByGlobalFilter = <T extends Record<string, unknown>>(values: T[], globalFilter: string | null) => {
  if (!globalFilter) return values;
  const uppercaseGlobalFilter = globalFilter.toUpperCase();
  return values.filter((obj) =>
    Object.values(obj).some((value) => `${value as any}`.toUpperCase().includes(uppercaseGlobalFilter)),
  );
};
