import api from 'services/api';

import type { UserDirectory } from '../types';

const BASE_URL = 'configuration';

export const getDirectories = async () => {
  const { data } = await api.get(`${BASE_URL}/directories`);
  return data as UserDirectory[];
};
