import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TrendValuesParameters } from 'domain/historian/types';
import type { RootState } from 'store/rootReducer';

const initialState = {
  value: {} as TrendValuesParameters,
};

export const tagView = createSlice({
  name: 'tag-view',
  initialState,
  reducers: {
    createChart: (state, action: PayloadAction<TrendValuesParameters>) => {
      state.value = action.payload;
    },
  },
});

export const { createChart } = tagView.actions;

export const chartView = (state: RootState) => state.tagView;

export default tagView.reducer;
