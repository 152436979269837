import { Loader } from '@progress/kendo-react-indicators';

import styles from './BusyContext.module.scss';

type Types = 'pulsing' | 'infinite-spinner' | 'converging-spinner';

interface BusyContextProps {
  isLoading: boolean;
  type?: Types;
}

// eslint-disable-next-line no-confusing-arrow
const BusyContext = ({ isLoading, type = 'converging-spinner' }: BusyContextProps) =>
  isLoading ? <Loader className={styles.loader} size="large" type={type} /> : null;

export default BusyContext;

BusyContext.defaultProps = {
  type: 'converging-spinner',
};
