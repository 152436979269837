import { PermissionStruct } from 'hooks/usePermission';
import { TFunction } from 'react-i18next';

export type AppStatus = 'Development' | 'Preview' | 'Productize' | 'Released';

export enum AppId {
  Sophia = 1,
  DataAnalytics = 2002,
  SystemPerformanceMonitor = 3,
  AutonomousOperations = 101,
  PelletingCBM = 102,
  CMS = 103,
  ControlPerformanceAssessment = 2547,
  Inspection = 2548,
  WorkPermit = 2549,
  Downloads = 2550,
  DatabaseBackup = 2551,
  ProductionManagement = 3029,
  DigitalTwin = 3035,
  Motors = 5004,
  Kanban = 3033,
  Settings = 6100,
}

export interface MetrisApp {
  id: AppId;
  name: (t: TFunction) => string;
  image: string;
  status: AppStatus;
  path: Lowercase<string>;
  element: React.ReactNode;
  permissions?: PermissionStruct[];
}
