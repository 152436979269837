import { Label } from '@progress/kendo-react-labels';
import { Button, Input } from 'components/KendoReact';
import { LoginRequest, OppPlatformComponent } from 'domain/auth/types';
import { clearState, login } from 'features/auth/reducer/auth';
import i18next from 'i18next';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { capitalizeFirstLetter } from 'utils/string';

import ChangePassword from '../ChangePassword/ChangePassword';
import { MultiFactorAuthentication } from '../MultiFactorAuthentication';
import styles from './LoginForm.module.scss';

interface FormProps extends LoginRequest {
  remember: boolean;
}

const LoginForm = () => {
  const { t } = useTranslation(['common', 'auth']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mfaState, setMfaState] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>();
  const dispatch = useAppDispatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    const { username, password } = data;
    try {
      setIsSubmitting(true);
      const { forceChangePassword, mfa } = await dispatch(
        login({
          username,
          password,
          componentInfo: {
            componentType: OppPlatformComponent.MetrisWeb,
          },
        }),
      ).unwrap();

      if (mfa) {
        setMfaState(true);
        return;
      }

      if (forceChangePassword) {
        toast.error(i18next.t<string>('auth:Your-password-need-to-be-updated'));
        setEmail(username);
        setOldPassword(password);
        setShowChangePassword(forceChangePassword);
        return;
      }

      const redirectPath = redirect?.startsWith('/') ? redirect.substring(1) : redirect;
      navigate(`/${redirectPath ?? ''}`);
      toast.dismiss();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2 className={styles.header}>{t('auth:welcome-to-metris-ux')}</h2>
        <div className={styles.field}>
          <Label>{t('common:username-email')}</Label>
          <Controller
            name="username"
            control={control}
            rules={{ required: t('common:required') }}
            render={({ field }) => <Input {...field} placeholder={t('auth:enter-username')} />}
          />
          {errors.username?.message && (
            <small className={styles.error}>{capitalizeFirstLetter(errors.username.message)}</small>
          )}
        </div>

        <div className={styles.field}>
          <Label>{t('common:password')}</Label>
          <Controller
            name="password"
            control={control}
            rules={{ required: t('common:required') }}
            render={({ field }) => <Input {...field} type="password" placeholder={t('auth:enter-password')} />}
          />
          {errors.password?.message && (
            <small className={styles.error}>{capitalizeFirstLetter(errors.password.message)}</small>
          )}
        </div>

        {/* <div className={`${styles.field} ${styles.checkBox}`}>
      <Controller
        name="remember"
        control={control}
        render={({ field }) => <Checkbox {...field} label={t('auth:remember-me')} />}
      />
    </div> */}

        <div className={styles.footerButtons}>
          <Button
            className="capitalize"
            themeColor="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('auth:login')}
          </Button>
        </div>
      </form>
      {showChangePassword && (
        <Dialog
          style={{ minWidth: '30vw', minHeight: '40vh' }}
          header="Update Password"
          headerStyle={{ padding: '2rem 0.5rem 0rem 2rem' }}
          visible={showChangePassword}
          onHide={() => {}}
          closable={false}
        >
          <ChangePassword
            email={email}
            currentPassword={oldPassword}
            onSuccess={() => {
              reset({
                password: '',
                username: email,
              });
              setShowChangePassword(false);
            }}
          />
        </Dialog>
      )}
      {mfaState && <MultiFactorAuthentication display={mfaState} onClose={() => setMfaState(false)} />}
    </>
  );
};

export default LoginForm;
