import { apps } from 'features/apps';
import { LogoutPage } from 'features/auth/pages';
import { MainLayout } from 'features/main/components';
import { PermissionStruct } from 'hooks/usePermission';
import { Navigate } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { Dashboard } = lazyImport(() => import('features/main/pages'), 'Dashboard');

export const protectedRoutes = (hasPermission: (...permission: PermissionStruct[]) => boolean) => [
  { path: '*', element: <Navigate to="/app" /> },
  {
    path: 'app/*',
    element: <MainLayout />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'logout', element: <LogoutPage /> },
      ...apps
        .filter((a) => a.status !== 'Development' && (a.permissions?.length ? hasPermission(...a.permissions) : true))
        .map((app) => ({ ...app, path: `${app.path}/*` })),
    ],
  },
];
