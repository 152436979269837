import { useMediaQuery } from 'hooks';
import React, { Key, ReactElement, cloneElement, useCallback, useState } from 'react';

import { BarGraphCardProps } from '../BarGraphCard/BarGraphCard';
import styles from './BarGraphContainer.module.scss';

type Units = 'rem' | 'em' | 'vh' | '%' | 'px';
type Height = number | `${number}${Units}`;

interface BarGraphContainerProps {
  children: ReactElement<BarGraphCardProps>[];
  height: Height;
}

const BarGraphContainer = ({ children, height }: BarGraphContainerProps) => {
  const [zoomKey, setZoomKey] = useState<Key | null>(null);
  const { isMobile } = useMediaQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMinimize = useCallback(() => setZoomKey(null), []);

  const content = children.map((child) =>
    cloneElement(child, {
      layout: child.key === zoomKey ? 'large' : 'small',
    }),
  );
  const list = zoomKey ? content.filter((child) => child.key !== zoomKey) : content;
  const zoom = content.find((child) => child.key === zoomKey);

  return zoomKey ? (
    <div style={{ height: isMobile ? undefined : height }} className={styles.zoomContainer}>
      <div className={styles.cardZoom}>{zoom}</div>
      <div className={styles.cardList}>{list}</div>
    </div>
  ) : (
    <div className={styles.linearContainer}>{list}</div>
  );
};

export default BarGraphContainer;
