import styles from './StatusBadge.module.scss';

interface StatusBadgeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color: 'blue' | 'red' | 'green' | 'yellow' | 'grey';
  children: string;
}

const StatusBadge = ({ color, children, className, ...rest }: StatusBadgeProps) => {
  return (
    <span className={`${className ?? ''} ${styles.badge} ${styles[color]}`} {...rest}>
      {children}
    </span>
  );
};

export default StatusBadge;
