import { TabView as PrimeTabView, TabViewProps } from 'primereact/tabview';
import { ReactNode } from 'react';

import styles from './TabView.module.scss';

const TabView = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeTabView> &
  Readonly<TabViewProps> &
  Readonly<{ children?: ReactNode }>) => <PrimeTabView className={`${className} ${styles.tabView}`} {...props} />;

export default TabView;
