import { PanelProps, Panel as PrimePanel } from 'primereact/panel';
import { ReactNode } from 'react';

import styles from './Panel.module.scss';

const Panel = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimePanel> &
  Readonly<PanelProps> &
  Readonly<{ children?: ReactNode }>) => <PrimePanel className={`${className} ${styles.panel}`} {...props} />;

export default Panel;
