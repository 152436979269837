import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import metris from 'assets/images/logo-metris/logo_metris_negative.png';
import smallMetris from 'assets/images/metris_icon.png';
import { confirmCode, generateCode } from 'domain/auth/api';
import { LanguageSelector } from 'features/auth/components';
import ChangePassword from 'features/auth/components/ChangePassword/ChangePassword';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = () => {
  const { t } = useTranslation(['common', 'auth']);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'all' });
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const handleGenerateCode = async () => {
    const { email } = watch();
    await generateCode(email);
  };

  const handleConfirmCode = async () => {
    const { email, code } = watch();
    await confirmCode(email, code);
    setIsChangePasswordVisible(true);
  };

  return (
    <div className={styles.page}>
      <img className={styles.metrisLogo} src={metris} alt="metris logo" />
      <div className={styles.background}>
        <form className={styles.form}>
          <h3 className={styles.header}>{t('auth:reset-password')}</h3>
          <div className={styles.field}>
            <Label className={classNames({ 'p-error': errors.email })}> Email</Label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('common:required'),
                pattern: { value: /\S+@\S+\.\S+/, message: t('common:enter-a-valid-email') },
              }}
              render={({ field, fieldState }) => (
                <Input {...field} valid={!fieldState.error} placeholder={t('common:enter-your-email')} />
              )}
            />
            {errors.email && <small className="p-error">{errors.email.message as string}</small>}
          </div>
          <Button
            themeColor="primary"
            disabled={!!errors.email || !watch().email}
            type="button"
            onClick={handleGenerateCode}
          >
            {t('auth:generate-code')}
          </Button>
          <div className={styles.field}>
            <Label className={classNames({ 'p-error': errors.code })}>Code</Label>
            <Controller
              name="code"
              control={control}
              rules={{ required: t('common:required') }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  type="code"
                  valid={!fieldState.error}
                  placeholder={t('auth:enter-the-code-you-have-received')}
                />
              )}
            />
            {errors.code?.message && (
              <small className="p-error">{capitalizeFirstLetter(errors.code.message as string)}</small>
            )}
          </div>
          <Button themeColor="primary" disabled={!watch().code} type="button" onClick={handleConfirmCode}>
            {t('auth:confirm-code')}
          </Button>
          <Link to="/login" className={styles.backButton}>
            {t('common:back')}
          </Link>
          <div className={styles.languageSelector}>
            <LanguageSelector />
          </div>
          {isChangePasswordVisible && (
            <Dialog
              style={{ minWidth: '30vw', minHeight: '40vh' }}
              header="Update Password"
              headerStyle={{ padding: '2rem 0.5rem 0rem 2rem' }}
              visible={isChangePasswordVisible}
              onHide={() => setIsChangePasswordVisible(false)}
            >
              <ChangePassword email={watch().email} code={watch().code} />
            </Dialog>
          )}
        </form>
      </div>
      <p className={styles.leftText}>Foresee digitally</p>
      <div className={styles.rightText}>
        <img className={styles.smallMetrisLogo} src={smallMetris} alt="metris logo" />
        <span>ANDRITZ Digital IIoT Solution</span>
      </div>
      <div className={styles.bottomPage} />
    </div>
  );
};

export default ForgotPasswordPage;
