import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BusyContext } from 'components/common';
import { languageSelector, themeSelector } from 'features/main/reducers/settings';
import { Theme } from 'features/main/types';
import i18n from 'i18n';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import { Suspense, useEffect, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HashRouter as Router } from 'react-router-dom';
import { useAppSelector } from 'store';

import AppProvider from './contexts';
import { AppRoutes } from './routes';

const queryClient = new QueryClient();

const App = () => {
  const theme = useAppSelector(themeSelector);
  const oldTheme = useRef<Theme>('light');
  const language = useAppSelector(languageSelector);
  const { switcher, themes } = useThemeSwitcher();

  useEffect(() => {
    if (oldTheme.current !== theme) {
      PrimeReact.changeTheme?.(`${oldTheme.current}-theme.css`, `${theme}-theme.css`, 'prime-theme');
      oldTheme.current = theme;
    }
  }, [theme]);

  useEffect(() => {
    switcher({ theme: theme === 'dark' ? themes.dark : themes.light });
    document.body.classList.remove('theme--light', 'theme--dark');
    document.body.classList.add(`theme--${theme}`);
    document.documentElement.style.setProperty('--color-scheme', theme);
  }, [switcher, theme, themes.dark, themes.light]);

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <Suspense fallback={<BusyContext isLoading />}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        </Router>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
