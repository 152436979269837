import { useEffect } from 'react';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `Metris All-in-one Platform Web - ${title}`;
    return () => {
      document.title = 'Metris All-in-one Platform Web';
    };
  }, [title]);
};
