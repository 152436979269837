/* eslint-disable no-bitwise */
/**
 * rounds a float to two decimal places
 * @param {number} x - number
 * @returns {number} - number
 */
export const round2decimals = (x: number): number => Math.round((x + Number.EPSILON) * 100) / 100;

/**
 * Rounds a number to the given decimal places.
 * @param value - number that will be rounded, e.g. 12.345
 * @param decimal [2] - number of decimal plates to round to, e.g. 2
 * @returns {number} - rounded number
 */

export const roundDecimal = (value: number, decimal = 2): number => {
  const factor = 10 ** decimal;
  return Math.round(value * factor) / factor;
};

/**
 * Converts bytes to unsigned integer
 * @param {number} a - byte number
 * @param {number} r - byte number
 * @param {number} g - byte number
 * @param {number} b - byte number
 * @returns {number} unsigned integer
 */
export const toUInt32 = (a: number, r: number, g: number, b: number): number => {
  let value = 0;

  value += ((a * 255) >>> 0) << 24;
  value += r << 16;
  value += g << 8;
  value += b;

  return value;
};

/**
 * converts unsigned integer to bytes
 * @param color - unsigned integer
 * @returns {number[]} - byte number
 */
export const toBytesInt32 = (color: number) => {
  const a = (color & 0xff000000) >>> 24;
  const r = (color & 0x00ff0000) >> 16;
  const g = (color & 0x0000ff00) >> 8;
  const b = color & 0x000000ff;

  return { a: round2decimals(a / 255), r, g, b };
};
