import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import deleteIcon from 'assets/images/icons/Delete.png';
import editIcon from 'assets/images/icons/Edit.png';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CustomDataTable.module.scss';

type HandleDelete<T> = {
  onDelete: (data: T) => Promise<void>;
  itemTitle: (item: T) => string;
};

type HandleEdit<T> = {
  onEdit: (data: T) => Promise<void>;
  formContent: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CustomDataTableProps<T extends Record<string, any>> extends DataTableProps<Array<T>> {
  children: JSX.Element | JSX.Element[];
  value: T[];
  title?: string;
  rows?: number;
  onRefresh?: (() => void) | null;
  handleDelete?: HandleDelete<T> | null;
  handleEdit?: HandleEdit<T> | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDataTable = <T extends Record<string, any>>({
  children,
  value,
  title = '',
  rows = 10,
  onRefresh = null,
  handleDelete = null,
  handleEdit = null,
  ...rest
}: CustomDataTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [item, setItem] = useState<T | null>(null);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const { t } = useTranslation(['common']);

  const header = (
    <div className="table-header">
      {title}
      <Input
        className={styles.searchInput}
        type="search"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onInput={(e: any) => setGlobalFilter(e.target.value)}
        placeholder={t('search')}
      />
      {!!onRefresh && <Button icon="pi pi-refresh" />}
    </div>
  );

  const confirmDeleteItem = (itemToDelete: T) => {
    setItem(itemToDelete);
    setDeleteItemDialog(true);
  };

  const deleteButtonTemplate = (rowData: T) => (
    <Button imageUrl={deleteIcon} className="k-button k-flat k-primary" onClick={() => confirmDeleteItem(rowData)} />
  );

  const editButtonTemplate = (rowData: T) => (
    <Button
      imageUrl={editIcon}
      className="k-button k-flat k-primary"
      onClick={() => handleEdit && handleEdit.onEdit(rowData)}
    />
  );

  const closeDialog = () => {
    setDeleteItemDialog(false);
  };

  const handleDeleteButton = async () => {
    if (handleDelete) {
      await handleDelete.onDelete(item as T);
    }
    closeDialog();
  };

  return (
    <>
      <DataTable
        {...rest}
        value={value}
        header={header}
        paginator
        rows={rows}
        globalFilter={globalFilter}
        rowsPerPageOptions={[Math.ceil(rows / 2), rows, rows * 2]}
        paginatorTemplate={`FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
  RowsPerPageDropdown`}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage={t('items-found', { count: 0 })}
      >
        {handleEdit && (
          <Column
            headerStyle={{ width: '2rem' }}
            bodyStyle={{ padding: 0, textAlign: 'center' }}
            style={{ padding: 0 }}
            body={editButtonTemplate}
          />
        )}
        {handleDelete && (
          <Column
            headerStyle={{ width: '2rem' }}
            bodyStyle={{ padding: 0, textAlign: 'center' }}
            style={{ padding: 0 }}
            body={deleteButtonTemplate}
          />
        )}
        {children}
      </DataTable>
      {deleteItemDialog && (
        <Dialog title={t('confirm')} onClose={closeDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {item && <span>{t('delete-confirm', { item: handleDelete?.itemTitle(item) })}</span>}
          </div>
          <DialogActionsBar>
            <Button className="k-button k-flat k-primary" onClick={closeDialog}>
              {t('cancel')}
            </Button>
            <Button className={styles.deleteButton} onClick={handleDeleteButton}>
              {t('delete')}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default CustomDataTable;

CustomDataTable.defaultProps = {
  rows: 10,
  onRefresh: null,
  handleDelete: null,
  handleEdit: null,
  title: '',
};
