import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Mutex } from 'async-mutex';

import { setupSignalRConnection } from './connectionHub';

export interface NextTagValuesHandler {
  tagIds: number[];
  callback: TagValuesHandlerCallback;
}
export type TagValuesHandlerCallback = (tagValues: TagValues) => void;
export type TagValues = Map<number, number>;

const route = '/tagvalues';

const lock = new Mutex();

let connection: HubConnection | null = null;
let subscribers: number[] = [];
let handlers: NextTagValuesHandler[] = [];
const tagValues: TagValues = new Map<number, number>();

const connect = async () => {
  if (!connection || connection.state === HubConnectionState.Disconnected) {
    connection = await setupSignalRConnection(route);

    connection?.on('OnNext', (tagIds: number[], values: number[]) => {
      tagIds.forEach((tagId, index) => {
        tagValues.set(tagId, values[index]);
      });
      handlers.forEach(({ callback }) => callback(tagValues));
    });
  }
};

const disconnect = async () => {
  if (connection?.state !== HubConnectionState.Connected) return;

  connection.off('OnNext');
  await connection.stop();
};

const subscribe = async (ids: number[]) => {
  if (ids.every((id) => subscribers.includes(id))) return;
  if (ids.length === 0) return;

  await connect();
  if (connection?.state !== HubConnectionState.Connected) return;

  if (subscribers.length) {
    await connection.invoke('Unsubscribe', subscribers);
  }
  subscribers = [...new Set([...subscribers, ...ids])];
  await connection.invoke('Subscribe', subscribers);
};

const unsubscribe = async () => {
  if (connection?.state !== HubConnectionState.Connected) return;

  await connection.invoke('Unsubscribe', subscribers);
  await disconnect();
  subscribers = [];
};

export const addHandler = async (handler: NextTagValuesHandler) => {
  if (handler.tagIds.length === 0) return;
  if (handlers.some(({ callback }) => callback === handler.callback)) return;

  const release = await lock.acquire();
  await subscribe(handler.tagIds);
  handlers.push(handler);
  release();
};

export const removeHandler = async (handler: NextTagValuesHandler) => {
  if (handler.tagIds.length === 0) return;

  const release = await lock.acquire();
  handlers = handlers.filter(({ callback }) => callback !== handler.callback);
  release();

  // Avoid a disconnect followed by a quick reconnect
  // Ensuring that after 5 sec there are no new handlers
  if (handlers.length !== 0) return;
  setTimeout(async () => {
    if (handlers.length !== 0) return;
    await unsubscribe();
  }, 5000);
};

export const getTagValues = (): TagValues => new Map(tagValues);
