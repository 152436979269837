import { useEffect, useRef, useState } from 'react';
import { NextTagValuesHandler, TagValues, addHandler, getTagValues, removeHandler } from 'services/tagValuesHub';

interface TagValuesState {
  tagValues: TagValues;
  isLoading: boolean;
}

const comparer = (tagIds: number[], previous: TagValues, next: TagValues) =>
  tagIds.every((id) => previous.get(id) === next.get(id));

const refChanged = (ref: number[], tagIds: number[]) =>
  ref.length !== tagIds.length || ref.some((value, index) => value !== tagIds[index]);

const resetState = (tagIds: number[] = []) => {
  const tagValues = getTagValues();
  return {
    tagValues,
    isLoading: tagIds.length === 0 || tagIds.some((tagId) => tagValues.get(tagId) === undefined),
  };
};

export const useTagValues = (tagIds: number[], enabled = true) => {
  const ref = useRef<number[]>([]);
  const [state, setState] = useState<TagValuesState>(resetState());

  useEffect(() => {
    if (refChanged(ref.current, tagIds)) {
      setState(resetState(tagIds));
      ref.current = tagIds;
    }

    const handler: NextTagValuesHandler = {
      tagIds,
      callback: (next) =>
        setState((previous) =>
          comparer(tagIds, previous.tagValues, next)
            ? previous
            : {
                tagValues: new Map(next),
                isLoading: tagIds.length === 0 || tagIds.some((tagId) => next.get(tagId) === undefined),
              },
        ),
    };

    if (enabled) {
      addHandler(handler);
    } else {
      removeHandler(handler);
    }

    return () => {
      removeHandler(handler);
    };
  }, [tagIds, enabled]);

  return state;
};

// This hook should not re-render the component
export const useEarlySubscription = (tagIds: number[]) =>
  useEffect(() => {
    const handler: NextTagValuesHandler = {
      tagIds,
      callback: () => {},
    };
    addHandler(handler);
    return () => {
      removeHandler(handler);
    };
  }, [tagIds]);
