import { DialogProps, Dialog as PrimeDialog } from 'primereact/dialog';
import { ReactNode } from 'react';

import styles from './Dialog.module.scss';

const Dialog = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeDialog> &
  Readonly<DialogProps> &
  Readonly<{ children?: ReactNode }>) => <PrimeDialog className={`${className} ${styles.dialog}`} {...props} />;

export default Dialog;
