import userIcon from 'assets/images/icons/User.png';
import { Switch } from 'components/KendoReact';
import { LanguageSelector } from 'features/auth/components';
import { avatarSelector, userSelector } from 'features/auth/reducer/auth';
import { themeSelector, toggleTheme } from 'features/main/reducers/settings';
import { useClickOutside } from 'hooks';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './UserPopupMenu.module.scss';

interface UserPopupMenuProps {
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonRef: MutableRefObject<any>;
}

const UserPopupMenu = ({ handleClose, buttonRef }: UserPopupMenuProps) => {
  const { t } = useTranslation('common');
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, buttonRef, handleClose);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const theme = useAppSelector(themeSelector);
  const avatar = useAppSelector(avatarSelector);

  const toggleDarkMode = () => dispatch(toggleTheme());

  return (
    <div ref={wrapperRef} className={`${styles.menu} ${theme === 'dark' ? 'theme--dark' : 'theme--light'}`}>
      <div className={styles.item}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            <img alt="user" src={avatar || userIcon} />
          </div>
          <div className={styles.column}>
            <h5>{user?.name}</h5>
            <span>{user?.email}</span>
          </div>
        </div>
      </div>
      {/* <div className={styles.separator} />
      <div className={styles.item}>
        <Link onClick={handleClose} to="profile" className={styles.link}>
          {t('my-profile')}
        </Link>
      </div> */}
      <div className={styles.separator} />
      <div className={styles.item}>
        <LanguageSelector />
      </div>
      {/* <div className={styles.item}>
        <Link onClick={handleClose} to="settings" className={styles.link}>
          {t('settings')}
        </Link>
      </div> */}
      <div className={styles.item}>
        <Link id="metris-logout" onClick={handleClose} to="logout" className={styles.link}>
          {t('logout')}
        </Link>
      </div>
      <div className={styles.separator} />
      <div className={styles.item}>
        <div className={styles.darkMode}>
          <span>{t<string>('dark-mode')}</span>
          <Switch id="to-dark-mode" checked={theme === 'dark'} focused onChange={toggleDarkMode} />
        </div>
      </div>
    </div>
  );
};

export default UserPopupMenu;
