import axios from 'axios';
import i18next from 'i18next';
import { toast } from 'react-toastify';

interface RenderExceptionMessageProps {
  error: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorsList = (data: any) => {
  if ('errors' in data || 'Errors' in data) {
    const errorsList: JSX.Element[] = [];
    const { errors, Errors, message, Message } = data as {
      errors: { [key: string]: string[] };
      Errors: { [key: string]: string[] };
      message: { [key: string]: string };
      Message: { [key: string]: string };
    };
    if (errors || Errors) {
      const keys = errors ? Object.keys(errors) : Object.keys(Errors);
      const errorsObject = errors ?? Errors;
      keys.forEach((key: string) => {
        if (Array.isArray(errorsObject[key])) {
          errorsObject[key].forEach((value: string) => {
            const values = value.split('.');
            if (values.length > 1 && values[1].includes('Path')) {
              errorsList.push(<li key={values[0]}>{values[0]}</li>);
            } else {
              errorsList.push(<li key={value}>{value}</li>);
            }
          });
        }
      });
      return errorsList;
    }
    if (message || Message) {
      return [
        <p>
          <>{message || Message}</>
        </p>,
      ];
    }
  }
  return [];
};

export const RenderExceptionMessage = ({ error }: RenderExceptionMessageProps): JSX.Element => {
  if (axios.isAxiosError(error)) {
    if (error.response?.data) {
      let errorsList: JSX.Element[] = [];
      if (typeof error.response?.data === 'string') {
        const data = JSON.parse(error.response?.data);
        errorsList = extractErrorsList(data);
      } else {
        errorsList = extractErrorsList(error.response?.data);
      }
      return <ul>{errorsList.length ? errorsList : error.message}</ul>;
    }
    return <p>{error.message}</p>;
  }
  if (error instanceof Error) {
    return <p>{error.message}</p>;
  }
  if (typeof error === 'string') {
    return <p>{error}</p>;
  }
  return <p>{i18next.t<string>('common:unknown-error')}</p>;
};

export const handleException = (
  error: unknown,
  message: string,
  toastId?: string,
  autoClose: number | false | undefined = 5000,
) => {
  return toast.error(
    <div>
      <p>{message}</p>
      <div>
        <RenderExceptionMessage error={error} />
      </div>
    </div>,
    { toastId, autoClose },
  );
};
