import i18next from 'i18next';
import { toast } from 'react-toastify';
import api from 'services/api';

import type { Team } from '../types';

export const getTeams = async () => {
  try {
    const { data } = await api.get<Team[]>('configuration/teams');
    return data;
  } catch (error) {
    const cause = error instanceof Error ? error : new Error('Unknown Error');
    const reason = cause.message;
    toast.error(i18next.t<string>('common:errors.failed-to-get-teams', { reason }), {
      toastId: 'teams-get',
    });
    throw Error(i18next.t<string>('common:errors.failed-to-get-teams', { reason }), { cause });
  }
};

export const getTeam = async (id: number) => {
  try {
    const { data } = await api.get(`configuration/teams/${id}`);
    return data as Team;
  } catch (e: unknown) {
    throw Error(i18next.t<string>('common:errors.failed-to-get-team'), {
      cause: e as Error,
    });
  }
};

export const deleteTeam = async (id: number) => {
  try {
    await api.delete(`configuration/teams/${id}`);
    toast.success(i18next.t<string>('settings:team-deleted-successfully'));
  } catch (e: unknown) {
    throw Error(i18next.t<string>('settings:fail-to-delete-team'), {
      cause: e as Error,
    });
  }
};

export const saveTeam = async (team: Team) => {
  try {
    await api.post('configuration/team', team);
    toast.success(i18next.t<string>('workPermit:user-type-created-successfully'));
  } catch (e: unknown) {
    throw Error(i18next.t<string>('workPermit:failed-to-create-user-type'), {
      cause: e as Error,
    });
  }
};
