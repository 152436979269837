import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { UserToken } from 'domain/auth/types';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { env } from '../utils/env';
import storage from './storage';

export const ORIGIN = window.location.origin.toString();

const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    if (env.REACT_APP_BASE_URL) {
      return env.REACT_APP_BASE_URL;
    }

    if (env.REACT_APP_BASE_URL === '') {
      return ORIGIN;
    }
  }

  return process.env.REACT_APP_BASE_URL;
};

export const BASE_URL = getBaseUrl();
export const BASE_API_URL = `${BASE_URL}/api`;

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ORIGIN,
  },
});

const CheckApiOnline = () =>
  axios
    .get(`${BASE_API_URL}/test`)
    .then(() => true)
    .catch(() => false);

const errorHandler = async (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401 && window.location.href !== `${window.location.origin}/metris.web#/login`) {
      toast.error(i18next.t<string>('auth:user-not-authenticated'), {
        toastId: '401error',
        position: 'top-right',
        autoClose: false,
      });
      window.location.href = `${window.location.origin}/metris.web#/app/logout`;
    }
    if (error.response?.status === 500) {
      toast.error(`${error.response.statusText}`, {
        position: 'top-right',
        toastId: '500error',
        autoClose: false,
      });
    }
    if (!error.response) {
      if (await CheckApiOnline()) {
        toast.error('Network Error! Check your network connection and try again.', {
          position: 'top-right',
          toastId: 'networkError',
          autoClose: false,
        });
      } else {
        toast.error(`Network Error! Api is not reachable. Check if service is running on url ${BASE_API_URL}`, {
          position: 'top-right',
          toastId: 'offlineError',
          autoClose: false,
        });
      }
    }
  }
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => {
    toast.dismiss('500error');
    toast.dismiss('networkError');
    return response;
  },
  (error) => errorHandler(error),
);

api.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers && !config.headers.authorization) {
    const token = storage.getItem<UserToken>('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token.id}`;
    }
  }

  return config;
});

export default api;
