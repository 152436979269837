import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';

import TabTitle from '../TabTitle/TabTitle';
import styles from './OverviewLayout.module.scss';

export type OverviewLayoutType = {
  addTabs: (tab: MainTab) => void;
};

export interface OverviewLayoutProps {
  title: ReactNode;
  icon: string;
  children: ReactNode;
}

export interface MainTab {
  id: string;
  title: ReactNode;
  icon?: string;
  content: ReactNode;
}

const OverviewLayout = forwardRef<OverviewLayoutType, OverviewLayoutProps>(
  ({ title, icon, children }: OverviewLayoutProps, ref) => {
    const [tabs, setTabs] = useState<MainTab[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useImperativeHandle(ref, () => ({
      addTabs(tab: MainTab) {
        const existingTab = tabs.find((t) => t.id === tab.id);
        if (existingTab) {
          setSelectedIndex(tabs.indexOf(existingTab) + 1);
        } else {
          const newTabs = [...tabs, tab];
          setTabs(newTabs);
          setSelectedIndex(newTabs.length);
        }
      },
    }));

    const handleSelect = (e: TabStripSelectEventArguments) => {
      setSelectedIndex(e.selected > tabs.length ? 0 : e.selected);
    };

    const removeTab = (id: string) => {
      const newTabs = tabs.filter((item) => item.id !== id);
      setTabs(newTabs);

      if (selectedIndex > newTabs.length) {
        setSelectedIndex(selectedIndex - 1);
      }
    };

    return (
      <TabStrip
        className={styles.tabContainer}
        selected={selectedIndex}
        onSelect={handleSelect}
        keepTabsMounted
        animation={false}
        tabContentStyle={{ height: '100%', padding: '0' }}
      >
        <TabStripTab title={<TabTitle id="0" title={title} icon={icon} mainTab />} key={0}>
          {children}
        </TabStripTab>
        {tabs?.map((item) => (
          <TabStripTab
            title={<TabTitle id={item.id} title={item.title} icon={item.icon} onTabRemove={removeTab} />}
            key={item.id}
          >
            {item.content}
          </TabStripTab>
        ))}
      </TabStrip>
    );
  },
);

export default OverviewLayout;
