import { DropdownProps, Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { ReactNode } from 'react';

import styles from './Dropdown.module.scss';

const Dropdown = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeDropdown> &
  Readonly<DropdownProps> &
  Readonly<{ children?: ReactNode }>) => <PrimeDropdown className={`${className} ${styles.dropdown}`} {...props} />;

export default Dropdown;
