import { Loader as KendoLoader } from '@progress/kendo-react-indicators';

import styles from './Loader.module.scss';

const Loader = () => (
  <div className={styles.container}>
    <KendoLoader className={styles.loader} size="large" type="converging-spinner" />
  </div>
);

export default Loader;
