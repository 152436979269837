/**
 * @param {string} string - andritz
 * @returns {string} capitalized string = Andritz
 */

export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1) ?? '';
}

export const includesIgnoreCase = (a: string | null, b: string) => a?.toLowerCase().includes(b.toLowerCase());
