import { Toolbar as PrimeToolbar, ToolbarProps } from 'primereact/toolbar';
import { ReactNode } from 'react';

import styles from './Toolbar.module.scss';

const Toolbar = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeToolbar> &
  Readonly<ToolbarProps> &
  Readonly<{ children?: ReactNode }>) => <PrimeToolbar className={`${className} ${styles.toolbar}`} {...props} />;

export default Toolbar;
