import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AssociatedItem } from 'domain/common/types';
import {
  EditableWorkPermit,
  WorkPermitActivity,
  WorkPermitApprovers,
  WorkPermitExecutant,
} from 'domain/safeWorkPermit/types';
import type { RootState } from 'store/rootReducer';

interface ProcedureState {
  editableWorkPermit: EditableWorkPermit | null;
}

const initialState: ProcedureState = {
  editableWorkPermit: null,
};

export const workPermitSlice = createSlice({
  name: 'work-permit',
  initialState,
  reducers: {
    setWorkPermit: (state, action: PayloadAction<EditableWorkPermit | null>) => {
      state.editableWorkPermit = action.payload;
    },
    setWorkPermitExecutants: (state, action: PayloadAction<WorkPermitExecutant[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.executants = action.payload;
      }
    },
    setWorkPermitSpecificPermits: (state, action: PayloadAction<AssociatedItem[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.specificPermits = action.payload;
      }
    },
    setWorkPermitHazards: (state, action: PayloadAction<AssociatedItem[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.hazards = action.payload;
      }
    },
    setWorkPermitPPEs: (state, action: PayloadAction<AssociatedItem[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.ppEs = action.payload;
      }
    },
    setWorkPermitActivities: (state, action: PayloadAction<WorkPermitActivity[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.activities = action.payload;
      }
    },
    setWorkPermitApprovers: (state, action: PayloadAction<WorkPermitApprovers[]>) => {
      if (state.editableWorkPermit) {
        state.editableWorkPermit.approvers = action.payload;
      }
    },
  },
});

export const {
  setWorkPermit,
  setWorkPermitExecutants,
  setWorkPermitSpecificPermits,
  setWorkPermitHazards,
  setWorkPermitPPEs,
  setWorkPermitActivities,
  setWorkPermitApprovers,
} = workPermitSlice.actions;

export const workPermitSelector = (state: RootState) => state.workPermit.editableWorkPermit;

export default workPermitSlice.reducer;
