import { Map as KendoMap, MapLayers, MapProps, MapTileLayer, TileUrlTemplateArgs } from '@progress/kendo-react-map';
import { ReactNode } from 'react';

const tileSubdomains = ['a', 'b', 'c'];
const tileUrl = (e: TileUrlTemplateArgs) => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
const attribution =
  '<span>&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors</span>';

interface CustomMapProps extends MapProps {
  children?: ReactNode;
}

const Map = ({ children, ...props }: CustomMapProps) => {
  return (
    <KendoMap {...props}>
      <MapLayers>
        <MapTileLayer urlTemplate={tileUrl} subdomains={tileSubdomains} attribution={attribution} />
        {children}
      </MapLayers>
    </KendoMap>
  );
};

Map.defaultProps = {
  children: null,
};

export default Map;
