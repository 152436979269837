import { UserGroup } from 'domain/auth/types';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import api from 'services/api';
import { handleException } from 'utils/exception';

import { CountryDTO, MaintenanceEngineerDTO, MillDTO, RatingDTO } from '../types';
import { ManagerDTO } from '../types/manager.interfaces';
import { DimensionDTO, DimensionType } from '../types/settings.interface';
import { UserDescription } from '../types/userDescription.interfaces';

const BASE_URL = 'configuration';

export const deleteDimensionAsync = async (id: number, type: DimensionType) => {
  try {
    await api.delete(`${BASE_URL}/dimensions/${type}/${id}`);
  } catch (e: unknown) {
    throw Error(i18next.t<string>('settings:failed-to-delete-dimension'), {
      cause: e as Error,
    });
  }
};

export const getDimensionsAsync = async (type: DimensionType) => {
  const { data } = await api.get<DimensionDTO[]>(`${BASE_URL}/dimensions/${type}`);
  return data;
};

export const getDimensionAsync = async (id: number, type: DimensionType) => {
  const { data } = await api.get<DimensionDTO>(`${BASE_URL}/dimensions/${type}/${id}`);
  return data;
};

export const saveDimensionAsync = async ({ dimension, type }: { dimension: DimensionDTO; type: DimensionType }) => {
  try {
    await api.post(`${BASE_URL}/dimensions/${type}`, dimension);
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('settings:failed-to-save-dimension'));
  }
};

export const rateDimensionAsync = async (rating: RatingDTO, type: DimensionType) => {
  try {
    await api.post(`${BASE_URL}/dimensions/${type}`, rating);
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('settings:failed-to-save-dimension'));
  }
};

export const getMaintenanceEngineers = async () => {
  const { data } = await api.get<MaintenanceEngineerDTO[]>(`${BASE_URL}/maintenanceengineers`);
  return data;
};

export const getMaintenanceEngineer = async (id: number) => {
  const { data } = await api.get<MaintenanceEngineerDTO>(`${BASE_URL}/maintenanceengineers/${id}`);
  return data;
};

export const saveMaintenanceEngineerAsync = async (maintenanceEngineer: MaintenanceEngineerDTO) => {
  try {
    await api.post(`${BASE_URL}/maintenanceengineers`, maintenanceEngineer);
  } catch (e: unknown) {
    handleException(e, i18next.t<string>('settings:failed-to-save-maintenance-engineer'));
  }
};

export const deleteMaintenanceEngineerAsync = async (id: number) => {
  const { data } = await api.delete(`${BASE_URL}/maintenanceengineers/${id}`);
  return data;
};

export const getManagerData = async (id: number) => {
  const { data } = await api.get<ManagerDTO>(`${BASE_URL}/managers/${id}`);
  return data;
};

export const getManagersAsync = async () => {
  const { data } = await api.get<ManagerDTO[]>(`${BASE_URL}/managers`);
  return data;
};

export const getUserDescriptions = async () => {
  const { data } = await api.get<UserDescription[]>(`${BASE_URL}/userdescriptions`);
  return data;
};

export const deleteManager = async (id: number) => {
  const { data } = await api.delete<ManagerDTO[]>(`${BASE_URL}/managers/${id}`);
  return data;
};

export const saveManager = async (manager: ManagerDTO) => {
  const { data } = await api.post<ManagerDTO>(`${BASE_URL}/managers`, manager);
  return data;
};

export const getCountries = async () => {
  const { data } = await api.get<CountryDTO[]>(`${BASE_URL}/countries`);
  return data;
};

export const getMills = async () => {
  const { data } = await api.get<MillDTO[]>(`${BASE_URL}/mills`);
  return data;
};

export const getUserGroupAsync = async (id: number) => {
  const { data } = await api.get<UserGroup>(`${BASE_URL}/usergroups/${id}`);
  return data;
};

export const getUserGroupsAsync = async () => {
  const { data } = await api.get<UserGroup[]>(`${BASE_URL}/usergroups`);
  return data;
};

export const saveUserGroup = async (userGroup: UserGroup) => {
  try {
    await api.post(`${BASE_URL}/usergroups`, userGroup);
    toast.success(i18next.t<string>('settings:user-group-saved-successfully'));
  } catch (e: unknown) {
    throw Error(i18next.t<string>('settings:failed-to-save-user-group'), {
      cause: e as Error,
    });
  }
};

export const deleteUserGroup = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/usergroups/${id}`);
    toast.success(i18next.t<string>('settings:user-group-deleted-successfully'));
  } catch (e: unknown) {
    throw Error(i18next.t<string>('settings:failed-to-delete-user-group'), {
      cause: e as Error,
    });
  }
};
