export interface LoginRequest {
  username: string;
  password: string;
  componentInfo: ComponentInfo;
}
export interface ComponentInfo {
  componentType: OppPlatformComponent;
}

export enum OppPlatformComponent {
  NotSpecified = 0,
  OppUI = 1,
  OppService = 2,
  OppServiceJobs = 3,
  LogbookMobile = 4,
  ProcessEquipment = 5,
  OppHelpMobile = 6,
  ChatMobile = 7,
  CheckListMobile = 8,
  GlassPrototype = 9,
  DataAnalyticsMobile = 10,
  HydroMobileServiceApp = 11,
  ProcessDisplayMobile = 12,
  AssetManagement = 13,
  Connector = 14,
  Controller = 15,
  MyEquipmentMobile = 5,
  MetrisXService = 16,
  AssistantService = 17,
  MonitorService = 18,
  RezolveService = 19,
  MetrisWeb = 20,
}

export interface User {
  idUser: number;
  idCompany: number;
  name: string;
  email: string;
  username: string;
  phoneNumber: string;
  createdTimestamp: string;
  editedTimestamp: string;
  countryId: number | null;
  countryName: string;
  millId: number | null;
  millName: string;
  type: UserType;
  enabled: boolean;
  allowUIAccess: boolean;
  userGroupID: number;
  userGroup: UserGroup;
  useUserTypeDefinition: boolean;
  forceChangePassword: boolean;
  companyName: string;
  positionHeldID: number | null;
  positionHeldName: string;
  isIntegration: boolean;
  tokenWithoutExpiration: boolean;
}

export type UserToken = {
  id: string;
  userID: number;
  createdTimestamp: string;
};

export type LoginResponse = {
  data: UserToken;
};

export interface UserGroup {
  id: number;
  name: string;
  startupMenuID: number | null;
  showMenuBar: boolean;
  showMostFrequentlyAccessed: boolean;
  permissions: UserPermission;
  multiFactorAuthenticationEnable: boolean;
}

export enum MultiFactorAuthenticationResult {
  Valid = 0,
  Invalid = 1,
  Timeout = 2,
  UserNotFound = 3,
}

export enum UserType {
  Normal = 0,
  Anonymous = 1,
  System = 2,
  ActiveDirectory = 3,
}

export interface UserPermission {
  [key: string]: number;
  module01: number;
  module02: number;
  module03: number;
  module04: number;
  module05: number;
  module06: number;
  module07: number;
  module08: number;
  module09: number;
  module10: number;
  module11: number;
  module12: number;
  module13: number;
  module14: number;
  module15: number;
  module16: number;
  module17: number;
  module18: number;
  module19: number;
  module20: number;
  module21: number;
  module22: number;
  module23: number;
  module24: number;
  module25: number;
  module26: number;
  module27: number;
  module28: number;
  module29: number;
  module30: number;
  module31: number;
  module32: number;
  module33: number;
  module34: number;
  module35: number;
  module36: number;
  module37: number;
  module38: number;
  module39: number;
  module40: number;
}

export enum SignInIdentificationType {
  SignUp,
  ForgotPassword,
}

export interface SignInCredential {
  email: string;
  type: SignInIdentificationType;
  code: string;
  name: string;
  password: string;
  phoneNumber: string;
}

export interface CredentialsUpdate {
  currentPassword: string;
  newPassword: string;
}

export enum CredentialsUpdateResult {
  Success,
  InvalidPassword,
}
