import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Button } from 'components/KendoReact';
import { changePassword, forceChangePassword } from 'domain/auth/api';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ChangePassword.module.scss';

interface ChangePasswordProps {
  email: string;
  code?: string;
  currentPassword?: string;
  onSuccess?: () => void;
}

const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
const eightCharsOrMore = /.{8,}/g; // eight characters or more

const ChangePassword = ({ email, code, currentPassword, onSuccess }: ChangePasswordProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const [meter, setMeter] = useState(false);
  const {
    control,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const password = watch().newPassword ?? '';

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
    differentFromFormer: password !== currentPassword,
  };

  const passwordStrength = Object.values(passwordTracker).filter((value) => value).length;

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      const { newPassword } = watch();
      if (code) {
        await changePassword(email, code, newPassword);
      } else if (currentPassword) {
        await forceChangePassword(email, { currentPassword, newPassword });
      }
      navigate('/login');
      onSuccess?.();
    } catch {
      // TODO: display toast message saying there was an error while changing password
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.form}>
      <div className={styles.field}>
        <Label className={classNames({ 'p-error': errors.newPassword })}> New Password</Label>
        <Controller
          name="newPassword"
          control={control}
          rules={{
            required: t('common:required'),
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="password"
              valid={!fieldState.error}
              placeholder={t('auth:enter-your-new-password')}
              onFocus={() => setMeter(true)}
              onChange={(value) => {
                field.onChange(value);
                if (watch().confirmPassword) trigger('confirmPassword');
              }}
            />
          )}
        />
        {errors.newPassword && <small className="p-error">{errors.newPassword.message as string}</small>}
        {meter && (
          <>
            {passwordStrength < 6 && <span>{t('auth:your-password-must-contain')}: </span>}
            <ul>
              {!passwordTracker.uppercase && <li>{t('common:uppercase')}</li>}
              {!passwordTracker.lowercase && <li>{t('common:lowercase')}</li>}
              {!passwordTracker.specialChar && <li>{t('common:special-character')}</li>}
              {!passwordTracker.number && <li>{t('common:number')}</li>}
              {!passwordTracker.eightCharsOrGreater && <li>{t('common:eight-characters-or-more')}</li>}
              {!passwordTracker.differentFromFormer && (
                <li>{t('common:it-should-be-different-from-the-former-one')}</li>
              )}
            </ul>
          </>
        )}
      </div>
      <div className={styles.field}>
        <Label className={classNames({ 'p-error': errors.confirmPassword })}> Confirm Password</Label>
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: t('common:required'),
            validate: (value: string) => value === watch().newPassword || t('auth:the-passwords-need-to-match'),
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="password"
              valid={!fieldState.error}
              placeholder={t('auth:confirm-your-new-password')}
            />
          )}
        />
        {errors.confirmPassword && <small className="p-error">{errors.confirmPassword.message as string}</small>}
      </div>
      <Button
        themeColor="primary"
        disabled={!isValid || passwordStrength < 5}
        loading={loading}
        type="button"
        onClick={handleChangePassword}
      >
        {t('auth:change-password')}
      </Button>
      {!currentPassword && (
        <Link to="/login" className={styles.backButton}>
          {t('common:cancel')}
        </Link>
      )}
    </form>
  );
};

ChangePassword.defaultProps = {
  code: undefined,
  currentPassword: undefined,
  onSuccess: undefined,
};

export default ChangePassword;
