import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getMultiFactorAuthentication,
  getUser,
  getUserGroup,
  getUserPermission,
  loginByToken,
  loginWithEmailAndPassword,
  postMultiFactorAuthenticationCode,
} from 'domain/auth/api';
import { LoginRequest, OppPlatformComponent, User, UserPermission, UserToken } from 'domain/auth/types';
import { getUserAvatar } from 'domain/configuration/api';
import i18next from 'i18next';
import storage from 'services/storage';
import type { RootState } from 'store/rootReducer';

// 'type' is necessary to avoid circular dependency

interface AuthState {
  token: UserToken | null;
  user: User | null;
  avatar: string | null;
  permissions: UserPermission | null;
  error: unknown;
}

const initialState: AuthState = {
  token: null,
  user: null,
  avatar: null,
  permissions: null,
  error: null,
};

interface LoginResponse {
  token: UserToken | null;
  user: User | null;
  avatar: string | null;
  permissions: UserPermission | null;
  forceChangePassword?: boolean;
  mfa?: boolean;
}

let mfaPostToken: UserToken;

export const login = createAsyncThunk<LoginResponse, LoginRequest>(
  'auth/login',
  async ({ username, password }: LoginRequest, thunkAPI) => {
    try {
      const data = await loginWithEmailAndPassword({
        username,
        password,
        componentInfo: {
          componentType: OppPlatformComponent.MetrisWeb,
        },
      });
      storage.setItem('token', data);

      // validate whether password has to change
      const userData = await getUser(data.userID);
      const mfa = await getMultiFactorAuthentication();
      const group = await getUserGroup(userData.userGroupID);

      if (userData.forceChangePassword) {
        storage.clearStorage();
        return {
          token: null,
          user: null,
          avatar: null,
          permissions: null,
          forceChangePassword: userData.forceChangePassword,
        };
      }

      const permissions = await getUserPermission();
      let avatar = null;
      try {
        avatar = await getUserAvatar(data.userID);
      } catch (error) {
        avatar = null;
      }
      if (mfa && group.multiFactorAuthenticationEnable && userData.username !== '_system') {
        storage.clearStorage();
        mfaPostToken = data;
        return {
          token: null,
          user: userData,
          avatar,
          permissions,
          mfa: true,
        };
      }
      return { token: data, user: userData, avatar, permissions, forceChangePassword: false, mfa };
    } catch (error) {
      return thunkAPI.rejectWithValue(`${error}`);
    }
  },
);

export const tokenLogin = createAsyncThunk<LoginResponse, string>(
  'auth/tokenLogin',
  async (token: string, thunkAPI) => {
    try {
      const { data } = await loginByToken(token);
      storage.setItem('token', data);
      const userData = await getUser(data.userID);
      const permissions = await getUserPermission();
      let avatar = null;
      try {
        avatar = await getUserAvatar(data.userID);
      } catch (error) {
        avatar = null;
      }
      return { token: data, user: userData, avatar, permissions };
    } catch (error) {
      return thunkAPI.rejectWithValue(`${error}`);
    }
  },
);

export const mfaCodeValidate = createAsyncThunk<UserToken, string>('auth/mfaCodeValidation', async (code, thunkAPI) => {
  try {
    storage.setItem('token', mfaPostToken);
    if (!mfaPostToken) {
      throw new Error(i18next.t<string>('auth:login-in'));
    }
    await postMultiFactorAuthenticationCode(code);
    return mfaPostToken;
  } catch (error) {
    return thunkAPI.rejectWithValue(`${error}`);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
    },
    logout: (state) => {
      storage.clearStorage();
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(login.pending, tokenLogin.pending), (state) => {
      state.error = null;
    });
    builder.addMatcher(isAnyOf(login.fulfilled, tokenLogin.fulfilled), (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.avatar = action.payload.avatar;
      state.permissions = action.payload.permissions;
    });
    builder.addMatcher(isAnyOf(login.rejected, tokenLogin.rejected), (state, action) => {
      state.error = action.payload as string;
    });
    builder.addMatcher(isAnyOf(mfaCodeValidate.fulfilled), (state, action) => {
      state.token = action.payload;
    });
  },
});

export const { clearState, logout } = authSlice.actions;

export const userSelector = (state: RootState) => state.auth.user;
export const isLoggedInSelector = (state: RootState) => !!state.auth.token;
export const avatarSelector = (state: RootState) => state.auth.avatar;
export const permissionSelector = (state: RootState) => state.auth.permissions;

export default authSlice.reducer;
