import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { TreeViewDataItem } from 'features/dataAnalytics/types';
import type { RootState } from 'store/rootReducer';

import { TreeViewDataList } from '../components/TagListContainer/tagListContainer.interfaces';

type TreeViewDataItemState = {
  selectedFolderId: number | null;
  folders: TreeViewDataList[];
  perspectives: TreeViewDataItem[];
};

const initialState: TreeViewDataItemState = {
  selectedFolderId: null,
  folders: [],
  perspectives: [],
};

export const treeViewTagListsSlice = createSlice({
  name: 'treeViewTagLists',
  initialState,
  reducers: {
    setSelectedFolder: (state, action: PayloadAction<number | null>) => {
      state.selectedFolderId = action.payload;
    },
    setFolders: (state, action: PayloadAction<TreeViewDataList[]>) => {
      state.folders = action.payload;
    },
    setPerspectives: (state, action: PayloadAction<TreeViewDataItem[]>) => {
      state.perspectives = action.payload;
    },
  },
});

export const { setSelectedFolder, setFolders, setPerspectives } = treeViewTagListsSlice.actions;

export const selectedFolderSelector = (state: RootState) => state.treeViewTagLists.selectedFolderId;
export const selectFolders = (state: RootState) => state.treeViewTagLists.folders;
export const selectPerspectives = (state: RootState) => state.treeViewTagLists.perspectives;

export default treeViewTagListsSlice.reducer;
