import { Button } from '@progress/kendo-react-buttons';
import { LinearGauge, LinearGaugeProps } from '@progress/kendo-react-gauges';
import { Loader } from '@progress/kendo-react-indicators';
import lineChart from 'assets/images/icons/LineChart.png';
import { ExpandableContext } from 'components/common/Expandable/ExpandableContext';
import { RenderWhenTabFocused } from 'components/common/RenderWhenTabFocused';
import { CSSProperties, useContext, useMemo } from 'react';
import { roundDecimal } from 'utils/number';

import styles from './BarGraphCard.module.scss';

interface Range {
  from: number;
  to: number;
  opacity?: number;
  color: string;
}

export interface BarGraphCardProps {
  id: number;
  title: string;
  value: number | undefined;
  engUnit: string;
  min?: number;
  max?: number;
  ranges?: Range[];
  layout?: 'small' | 'large';
  onTrendClick?: () => void;
  scale?: number;
  isLoading?: boolean;
}

const getColor = (value: number, min: number, max: number, ranges: Range[]) => {
  if (value > max) return ranges.reduce((acc, item) => (acc.to > item.to ? acc : item), ranges[0]).color;
  if (value < min) return ranges.reduce((acc, item) => (acc.from < item.from ? acc : item), ranges[0]).color;
  const range = ranges.find((r) => value >= r.from && value <= r.to);
  return range?.color || '#000';
};

const BarGraphCard = ({
  id,
  title,
  value,
  engUnit,
  ranges,
  min = 0,
  max = 100,
  onTrendClick,
  scale = 1,
  isLoading,
}: BarGraphCardProps) => {
  const { zoomKey } = useContext(ExpandableContext);
  const styleScale = { '--scale': scale } as CSSProperties;

  const isZoom = Number(zoomKey) === id;
  const isXxl = window.innerWidth > 1400 && isZoom;

  const linearOptions: LinearGaugeProps = useMemo(() => {
    const minorUnit = Math.round((max - min) / 10);
    return {
      scale: {
        min,
        minorUnit,
        majorUnit: minorUnit * 5,
        majorTicks: { size: isXxl ? 20 : 10, width: 2 },
        minorTicks: { size: isXxl ? 25 : 10, width: 1 },
        max,
        ranges,
        vertical: false,
        rangeSize: isXxl ? 10 : 4,
        labels: {
          padding: 0.5,
          font: isZoom ? '18px sans-serif' : '12px sans-serif',
        },
      },
      pointer: {
        value: roundDecimal(value ?? max),
        color: getColor(value ?? max, min, max, ranges ?? []),
        size: isXxl ? 45 : 15,
      },
      style: {
        width: '100%',
      },
    };
  }, [isXxl, isZoom, max, min, ranges, value]);

  return (
    <span className={isZoom ? styles.zoomContainer : styles.container} style={styleScale}>
      <div className={styles.topBar}>
        {onTrendClick && <Button imageUrl={lineChart} fillMode="flat" size="small" onClick={onTrendClick} />}
      </div>
      {isZoom && <div className={styles.titleFull}>{title}</div>}
      <div className={styles.valueContainer}>
        {isLoading ? (
          <Loader type="pulsing" />
        ) : (
          <>
            <span className={isZoom ? styles.zoomValue : styles.value}>{roundDecimal(value ?? max, 1).toFixed(1)}</span>
            <span className={isZoom ? styles.zoomUnit : styles.unit}>{engUnit}</span>
          </>
        )}
      </div>
      <RenderWhenTabFocused className={styles.barGraphContainer}>
        <LinearGauge {...linearOptions} />
      </RenderWhenTabFocused>
    </span>
  );
};

BarGraphCard.defaultProps = {
  ranges: undefined,
  layout: 'small',
  min: 0,
  max: 100,
  onTrendClick: undefined,
  scale: 1,
  isLoading: undefined,
};

export default BarGraphCard;
