import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  JsonHubProtocol,
  LogLevel,
} from '@microsoft/signalr';
import { UserToken } from 'domain/auth/types';
import { toast } from 'react-toastify';
import storage from 'services/storage';

import { BASE_URL, ORIGIN } from './api';

const isDev = process.env.NODE_ENV === 'development';

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
  } catch (err) {
    toast.error(`SignalR Connection Error:  ${err}`, {
      position: 'top-right',
      toastId: 'signalr-connection-error',
      autoClose: false,
    });
  }
};

export const setupSignalRConnection = async (connectionHub: string) => {
  const token = storage.getItem<UserToken>('token');
  const options: IHttpConnectionOptions = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    accessTokenFactory: () => token?.id ?? '',
    headers: {
      'Access-Control-Allow-Origin': ORIGIN,
    },
  };

  const connection = new HubConnectionBuilder()
    .withUrl(`${BASE_URL}${connectionHub}`, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build();

  connection.serverTimeoutInMilliseconds = 1000 * 60 * 60;

  connection.onclose((err) => {
    if (err) {
      toast.error(`Connection closed due to error. Try refreshing this page to restart the connection ${err}`, {
        position: 'top-right',
        toastId: 'signalr-connection-error',
      });
    }
  });

  connection.onreconnecting((err) => {
    toast.error(`Connection lost due to error. Reconnecting.  ${err}`, {
      position: 'top-right',
      toastId: 'signalr-connection-error',
    });
  });

  connection.onreconnected((connectionId) => {
    toast.success(`Connection reestablished. Connected with connectionId:  ${connectionId}`, {
      position: 'top-right',
      toastId: 'signalr-connection-error',
    });
  });

  await startSignalRConnection(connection);

  return connection;
};
