import { Button } from '@progress/kendo-react-buttons';
import DeleteIcon from 'assets/images/icons/Delete.png';
import EditIcon from 'assets/images/icons/Edit.png';
import ViewDetailsIcon from 'assets/images/icons/ViewDetails.png';
import { ReactNode } from 'react';

import styles from './CardWithRibbon.module.scss';

interface CardWithRibbonProps {
  body: string | ReactNode;
  imageSrc: string;
  color?: 'blue' | 'green' | 'red' | 'yellow' | 'grey';
  onImageClick?: () => void;
  onActionClick?: () => void;
  actionButtonIcon?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  children?: ReactNode;
}

const CardWithRibbon = ({
  body,
  imageSrc,
  color = 'blue',
  onImageClick,
  onActionClick,
  actionButtonIcon = ViewDetailsIcon,
  onEdit,
  onDelete,
  children,
}: CardWithRibbonProps) => (
  <div className={`${styles.card} ${styles[color]}`}>
    <div className={styles.header}>
      <div className={styles.imageSide}>
        <div tabIndex={0} role="button" onClick={onImageClick} onKeyDown={onImageClick} className={styles.imageWrapper}>
          <img src={imageSrc} alt="Card Icon" />
        </div>
      </div>
      <div className={styles.body}>{body}</div>
    </div>
    {(onActionClick || onEdit || onDelete) && <hr className={styles.hr} />}
    <div className={styles.ribbon}>
      {onActionClick && (
        <Button onClick={onActionClick} imageUrl={actionButtonIcon} fillMode="flat" themeColor="primary" />
      )}
      {onEdit && <Button onClick={onEdit} imageUrl={EditIcon} fillMode="flat" themeColor="primary" />}
      {onDelete && <Button onClick={onDelete} imageUrl={DeleteIcon} fillMode="flat" themeColor="primary" />}
      {children}
    </div>
  </div>
);

CardWithRibbon.defaultProps = {
  color: 'blue',
  onImageClick: undefined,
  onActionClick: ViewDetailsIcon,
  actionButtonIcon: undefined,
  onEdit: undefined,
  onDelete: undefined,
  children: undefined,
};

export default CardWithRibbon;
