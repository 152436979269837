import { DateTimePickerProps, DateTimePicker as Picker } from '@progress/kendo-react-dateinputs';
import { Popup, PopupProps } from '@progress/kendo-react-popup';

const CustomPopup = (props: PopupProps) => {
  return <Popup {...props} style={{ zIndex: 9999 }} />;
};

const DateTimePicker = (props: DateTimePickerProps) => {
  return <Picker {...props} popup={CustomPopup} />;
};

export default DateTimePicker;
