import { Button } from 'components/KendoReact';
import { useCallback } from 'react';

import styles from './CrudDataTable.module.scss';

interface ActionButtonProps<T> {
  id: string;
  item: T;
  disabled?: boolean;
  theme: string;
  imageUrl: string;
  onClick: (itemToEdit: T) => void;
  title: string;
}

const ActionButton = <T,>({ id, item, disabled, theme, imageUrl, onClick, title }: ActionButtonProps<T>) => {
  const handleClick = useCallback(() => onClick(item), [item, onClick]);

  return (
    <Button
      id={id}
      type="button"
      className={`${styles.actionButton} ${styles[theme]}`}
      fillMode="flat"
      disabled={disabled}
      imageUrl={imageUrl}
      onClick={handleClick}
      title={title}
    />
  );
};

export default ActionButton;

ActionButton.defaultProps = {
  disabled: undefined,
};
