import { useQueryClient } from '@tanstack/react-query';
import { BusyContext } from 'components/common';
import { isLoggedInSelector, logout, tokenLogin } from 'features/auth/reducer/auth';
import { setLanguage, setTheme } from 'features/main/reducers/settings';
import { Language, Theme } from 'features/main/types';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';

const TokenPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useRef(useAppSelector(isLoggedInSelector));
  const queryClient = useQueryClient();

  const token = searchParams.get('token');
  const redirect = searchParams.get('redirect');
  const hideNavbar = searchParams.get('navbar') === 'false';
  const theme = searchParams.get('theme') as Theme | null;
  const lang = searchParams.get('lang');

  useEffect(() => {
    async function loginAsync(tokenArg: string) {
      try {
        await dispatch(tokenLogin(tokenArg)).unwrap();
        if (theme === 'dark' || theme === 'light') {
          dispatch(setTheme(theme));
        }
        if (lang) {
          dispatch(setLanguage(lang as Language));
        }
        navigate(`/${redirect ?? ''}?navbar=${!hideNavbar}`);
      } catch {
        toast.error('Token is invalid or has expired!');
        navigate('/login');
      }
    }

    if (isLoggedIn.current) {
      dispatch(logout());
      queryClient.invalidateQueries();
    }

    if (token) {
      loginAsync(token);
    } else {
      navigate('/login');
    }
  }, [dispatch, hideNavbar, lang, navigate, queryClient, redirect, theme, token]);

  return <BusyContext isLoading />;
};

export default TokenPage;
