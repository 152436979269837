import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { CodeInput } from 'components/common/CodeInput';
import { mfaCodeValidate, userSelector } from 'features/auth/reducer/auth';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './MultiFactorAuthentication.module.scss';

interface MultiFactorAuthenticationProps {
  display: boolean;
  onClose: () => void;
}

const codeLength = 6;

export const MultiFactorAuthentication = ({ display, onClose }: MultiFactorAuthenticationProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const [code, setCode] = useState('');

  function hideEmail(email: any) {
    if (!email) {
      return '';
    }
    const [username, domain] = email.split('@');
    const hiddenUsername = `${username.charAt(0)}${'*'.repeat(username.length - 2)}${username.slice(-1)}`;
    return `${hiddenUsername}@${domain}`;
  }

  if (!display) {
    return null;
  }

  return (
    <Dialog
      title={t('auth:multi-factor-authentication')}
      minWidth="40vw"
      width={50}
      height={340}
      onClose={onClose}
      className={styles['my-dialog']}
    >
      <div className={styles.centralize}>{t('auth:authentication')}</div>
      <div className={styles['dialog-content']}>
        <CodeInput mode="int" length={codeLength} autoFocus onChange={setCode} />
      </div>
      <div className={styles.centralize}>{t('auth:enter-the-6-digits', { email: hideEmail(user?.email) })}</div>
      <span className={styles.centralize}>{t('auth:is-valid')}</span>

      <DialogActionsBar>
        <div className={styles.login}>
          <Button
            className="p-button-primary"
            style={{ width: '160px', height: '38px' }}
            onClick={async () => {
              await dispatch(mfaCodeValidate(code));
            }}
            disabled={code.length !== codeLength}
          >
            <span>{t('auth:validate-button')}</span>
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};
