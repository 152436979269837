import { isLoggedInSelector } from 'features/auth/reducer/auth';
import { usePermission } from 'hooks/usePermission';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useAppSelector } from 'store';

import { privateRoutes } from './private.routes';
import { protectedRoutes } from './protected.routes';
import { publicRoutes } from './public.routes';

export const AppRoutes = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { hasPermission } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && location.pathname) {
      if (location.pathname.startsWith('/app/') && !location.pathname.startsWith('/app/logout'))
        navigate(`/login?redirect=${location.pathname}`);
      else if (
        !(['/reset-password', '/login'].some((r) => location.pathname === r) || location.pathname.startsWith('/auth'))
      ) {
        navigate('/login');
      }
    }
  }, [isLoggedIn, location.pathname, navigate]);

  const routes = [...publicRoutes, ...(isLoggedIn ? protectedRoutes(hasPermission) : privateRoutes)];

  return useRoutes(routes);
};
