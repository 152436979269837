import { BusyContext } from 'components/common';
import { Suspense } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import Navbar from '../Navbar/Navbar';
import styles from './MainLayout.module.scss';

const MainLayout = () => {
  const [searchParams] = useSearchParams();

  const hideNavbar = searchParams.get('navbar') === 'false';

  return (
    <main id="app-root" className={`${styles.main} ${hideNavbar ? '' : styles.paddingTop}`}>
      {!hideNavbar && <Navbar />}
      <Suspense fallback={<BusyContext isLoading />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default MainLayout;
