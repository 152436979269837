import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '/metris.web/locales/{{lng}}/{{ns}}.json',
    },
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    partialBundledLanguages: true,
    ns: [],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format:
        process.env.NODE_ENV === 'test'
          ? (value) => value
          : (value, format) => {
              if (format === 'uppercase') return value.toUpperCase();
              if (format === 'lowercase') return value.toLowerCase();
              if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
              return value;
            },
    },
    resources: {},
  });

export default i18n;
