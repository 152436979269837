import addIcon from 'assets/images/icons/Add.png';
import { Button } from 'components/KendoReact';
import i18next from 'i18next';
import { ReactElement, ReactNode, RefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddItemDialog, AddItemDialogRef } from '../AddItemDialog';
import styles from './RibbonContainer.module.scss';

export interface HandleAdd {
  renderForm: (ref: RefObject<AddItemDialogRef>) => ReactNode | JSX.Element;
  dialogClassName?: string;
  title?: string;
}

interface RibbonContainerProps {
  onUpdate?: (() => void) | (() => Promise<void>);
  handleAdd?: HandleAdd;
  children?: ReactElement[] | ReactElement;
}

export const RibbonItem = ({ children }: { children: ReactNode }) => (
  <div className={styles.ribbonItem}>{children}</div>
);

export const RibbonGroup = ({ children }: { children: ReactNode }) => (
  <div className={styles.ribbonGroup}>{children}</div>
);

export const RibbonContainer = ({ onUpdate, handleAdd, children }: RibbonContainerProps) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { t } = useTranslation('common');
  const handleOpenDialog = useCallback(() => setIsAddOpen(true), []);
  const handleCloseDialog = useCallback(() => setIsAddOpen(false), []);

  return (
    <div className={styles.ribbonContainer}>
      {onUpdate && (
        <RibbonItem>
          <Button
            id="update-button"
            className={styles.button}
            onClick={onUpdate}
            icon="reload"
            fillMode="flat"
            themeColor="primary"
          >
            {i18next.format(t('update'), 'capitalize')}
          </Button>
        </RibbonItem>
      )}
      {handleAdd && (
        <RibbonItem>
          <Button
            id="add-button"
            title={t('common:add')}
            imageUrl={addIcon}
            fillMode="flat"
            themeColor="primary"
            onClick={handleOpenDialog}
          >
            {t('common:add')}
          </Button>
        </RibbonItem>
      )}
      {children}
      {isAddOpen && handleAdd && (
        <AddItemDialog
          title={handleAdd.title}
          visible={isAddOpen}
          dialogClassName={handleAdd.dialogClassName}
          handleClose={handleCloseDialog}
          renderForm={handleAdd.renderForm}
        />
      )}
    </div>
  );
};

RibbonContainer.defaultProps = {
  onUpdate: undefined,
  handleAdd: undefined,
  children: undefined,
};
