import metris from 'assets/images/logo-metris/logo_metris_negative.png';
import smallMetris from 'assets/images/metris_icon.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LanguageSelector, LoginForm } from '../../components';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.loginPage}>
      <img className={styles.metrisLogo} src={metris} alt="metris logo" />
      <div className={styles.background}>
        <div className={styles.container}>
          <LoginForm />
          <div className={styles.password}>
            <Link to="/reset-password" className={styles.link}>
              <p>{t('auth:i-forgot-my-password')}</p>
            </Link>
          </div>
          <LanguageSelector />
        </div>
      </div>
      <p className={styles.leftText}>Foresee digitally</p>
      <div className={styles.rightText}>
        <img className={styles.smallMetrisLogo} src={smallMetris} alt="metris logo" />
        <span>ANDRITZ Digital IIoT Solution</span>
      </div>
      <div className={styles.bottomPage} />
    </div>
  );
};

export default LoginPage;
