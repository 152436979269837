import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

import { SimpleTagListInfo, Structure } from '../types';

type TagListState = {
  selectedTagList: SimpleTagListInfo | null;
  structure: Structure;
};

const initialState: TagListState = {
  selectedTagList: null,
  structure: 'My Structure',
};

export const tagsList = createSlice({
  name: 'tagsList',
  initialState,
  reducers: {
    setSelectedTagList: (state, action: PayloadAction<SimpleTagListInfo | null>) => {
      state.selectedTagList = action.payload;
    },
    setStructure: (state, action: PayloadAction<Structure>) => {
      state.structure = action.payload;
      state.selectedTagList = null;
    },
  },
});

export const { setSelectedTagList, setStructure } = tagsList.actions;

export const selectedTagListSelector = (state: RootState) => state.tagsList.selectedTagList;
export const tagListStructureSelector = (state: RootState) => state.tagsList.structure;

export default tagsList.reducer;
