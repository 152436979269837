import { RefObject, useEffect, useMemo, useState } from 'react';

export const useFocusedTab = (elementRef: RefObject<HTMLElement>) => {
  const [isFocused, setIsFocused] = useState(true);

  const observer = useMemo(
    () => new ResizeObserver(() => setIsFocused(!!elementRef.current?.offsetParent)),
    [elementRef],
  );

  useEffect(() => {
    const node = elementRef.current;
    if (node) {
      observer.observe(node);
    }

    return () => observer.disconnect();
  }, [elementRef, observer]);

  return isFocused;
};
