/* eslint-disable max-lines */
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import addIcon from 'assets/images/icons/Add.png';
import deleteIcon from 'assets/images/icons/Delete.png';
import editIcon from 'assets/images/icons/Edit.png';
import unarchiveIcon from 'assets/images/icons/Undo.png';
import { Button } from 'components/KendoReact';
import { Dialog, Toolbar } from 'components/PrimeReact';
import { themeSelector } from 'features/main/reducers/settings';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { handleException } from 'utils/exception';

import ActionButton from './ActionButton';
import { HandleAdd, HandleArchive, HandleDelete, HandleEdit } from './CrudDataTable.interface';
import styles from './CrudDataTable.module.scss';
import { filterByGlobalFilter } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CrudDataTableProps<T extends Record<string, any>> extends DataTableProps<Array<T>> {
  children: ReactNode;
  left?: ReactNode;
  value: T[];
  title?: string;
  rows?: number;
  showSearch?: boolean;
  showPagination?: boolean;
  paginator?: boolean;
  onRefresh?: (() => void) | null;
  handleDelete?: HandleDelete<T> | null;
  handleArchive?: HandleArchive<T> | null;
  handleEdit?: HandleEdit<T> | null;
  handleAdd?: HandleAdd<T> | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CrudDataTable = <T extends Record<string, any>>({
  children,
  left,
  value,
  title = '',
  rows = 10,
  showSearch = true,
  showPagination = true,
  paginator = true,
  onRefresh = null,
  handleDelete = null,
  handleArchive = null,
  handleEdit = null,
  handleAdd = null,
  ...rest
}: CrudDataTableProps<T>) => {
  const theme = useAppSelector(themeSelector);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [item, setItem] = useState<T | null>(null);
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);
  const [showArchiveItemDialog, setShowArchiveItemDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onFilterChange = useCallback((e: InputChangeEvent) => {
    if (typeof e.target.value === 'string') setGlobalFilter(e.target.value);
  }, []);

  const header = (
    <div className="table-header">
      <label className={styles.text}>{title}</label>
      {showSearch && (
        <Input
          id="search-input"
          className={styles.searchInput}
          type="search"
          onChange={onFilterChange}
          placeholder={`${t('common:search')}...`}
        />
      )}
      {!!onRefresh && <Button icon="pi pi-refresh" onClick={onRefresh} />}
    </div>
  );

  const openNew = useCallback(() => setShowAddDialog(true), []);

  const leftToolbarTemplate = useCallback(
    () =>
      handleAdd && (
        <>
          <Tooltip
            disabled={handleAdd.canExecute ? handleAdd.canExecute() : true}
            mouseTrack
            target=".create-button-wrapper"
            content="Not enough permission to create items"
          />
          <div className="create-button-wrapper">
            <Button
              id="create-new-button"
              fillMode="flat"
              imageUrl={addIcon}
              disabled={handleAdd.canExecute ? !handleAdd.canExecute() : false}
              onClick={openNew}
            >
              {t('common:new')}
            </Button>
          </div>
        </>
      ),
    [handleAdd, openNew, t],
  );

  const confirmDeleteItem = useCallback((itemToDelete: T) => {
    setItem(itemToDelete);
    setShowDeleteItemDialog(true);
  }, []);

  const confirmArchiveItem = useCallback((itemToArchive: T) => {
    setItem(itemToArchive);
    setShowArchiveItemDialog(true);
  }, []);

  const handleOnEdit = useCallback((itemToEdit: T) => {
    // await .....
    setItem(itemToEdit);
    setShowEditDialog(true);
  }, []);

  const actionButtonTemplate = useCallback(
    (rowData: T) => {
      return (
        <>
          {handleEdit && (
            <ActionButton
              id="edit-item-button"
              disabled={handleEdit?.canExecute ? !handleEdit.canExecute(rowData) : false}
              imageUrl={editIcon}
              theme={theme}
              item={rowData}
              onClick={handleOnEdit}
              title={t('common:edit')}
            />
          )}
          {handleDelete && (
            <ActionButton
              id="delete-item-button"
              disabled={handleDelete?.canExecute ? !handleDelete.canExecute(rowData) : false}
              imageUrl={deleteIcon}
              theme={theme}
              item={rowData}
              onClick={confirmDeleteItem}
              title={t('common:delete')}
            />
          )}
          {handleArchive && (
            <ActionButton
              id="archive-item-button"
              disabled={handleArchive?.canExecute ? !handleArchive.canExecute(rowData) : false}
              imageUrl={handleArchive?.isArchived(rowData) ? unarchiveIcon : deleteIcon}
              theme={theme}
              item={rowData}
              onClick={confirmArchiveItem}
              title={handleArchive?.isArchived(rowData) ? t('common:unarchive') : t('common:archive')}
            />
          )}
        </>
      );
    },
    [handleEdit, theme, handleOnEdit, t, handleDelete, confirmDeleteItem, handleArchive, confirmArchiveItem],
  );

  const closeDeleteDialog = useCallback(() => {
    if (handleDelete?.onClose) {
      handleDelete.onClose();
    }
    setShowDeleteItemDialog(false);
  }, [handleDelete]);

  const closeArchiveDialog = useCallback(() => {
    if (handleArchive?.onClose) {
      handleArchive.onClose();
    }
    setShowArchiveItemDialog(false);
  }, [handleArchive]);

  const closeEditDialog = useCallback(() => {
    if (handleEdit?.onClose) {
      handleEdit?.onClose();
    }
    setShowEditDialog(false);
  }, [handleEdit]);

  const closeAddDialog = useCallback(() => {
    if (handleAdd?.onClose) {
      handleAdd.onClose();
    }
    setShowAddDialog(false);
  }, [handleAdd]);

  const handleDeleteButton = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleDelete?.onDelete(item as T);
      closeDeleteDialog();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.cause && error.message) {
        handleException(error.cause, error.message);
      } else {
        handleException(error, '');
      }
    } finally {
      setIsLoading(false);
    }
  }, [closeDeleteDialog, handleDelete, item]);

  const handleArchiveButton = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleArchive?.onArchive(item as T);
      closeArchiveDialog();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.cause && error.message) {
        handleException(error.cause, error.message);
      } else {
        handleException(error, '');
      }
    } finally {
      setIsLoading(false);
    }
  }, [closeArchiveDialog, handleArchive, item]);

  const handleEditButton = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleEdit?.onEdit(item as T);
      closeEditDialog();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.cause && error.message) {
        handleException(error.cause, error.message);
      } else {
        handleException(error, '');
      }
    } finally {
      setIsLoading(false);
    }
  }, [closeEditDialog, handleEdit, item]);

  const handleAddButton = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleAdd?.onAdd(item as T);
      closeAddDialog();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.cause && error.message) {
        handleException(error.cause, error.message);
      } else {
        handleException(error, '');
      }
    } finally {
      setIsLoading(false);
    }
  }, [closeAddDialog, handleAdd, item]);

  const renderHeader = useCallback(
    () =>
      item &&
      handleEdit && (
        <div className={styles.headerContainer}>
          <span>{handleEdit.itemName ? `${t('common:editing')} ${item[handleEdit.itemName]}` : handleEdit.title}</span>
        </div>
      ),
    [handleEdit, item, t],
  );

  const renderAddHeader = useCallback(
    () => (
      <div className={styles.headerContainer}>
        <span>{handleAdd?.title ?? t('common:create')}</span>
      </div>
    ),
    [handleAdd?.title, t],
  );

  const handleDeleteDialogFooter = useCallback(
    () => (
      <div className={styles.bottomButton}>
        <Button
          id="dialog-delete-button"
          themeColor="error"
          loading={handleDelete?.isLoading ?? isLoading}
          icon="check"
          onClick={handleDeleteButton}
        >
          {t('common:delete')}
        </Button>
        <Button onClick={closeDeleteDialog}>{t('common:cancel')}</Button>
      </div>
    ),
    [closeDeleteDialog, handleDelete?.isLoading, handleDeleteButton, isLoading, t],
  );

  const handleArchiveDialogFooter = useCallback(
    (rowData: T) => (
      <div className={styles.bottomButton}>
        <Button
          id="dialog-archive-button"
          themeColor="error"
          loading={handleArchive?.isLoading ?? isLoading}
          icon="check"
          onClick={handleArchiveButton}
        >
          {handleArchive?.isArchived(rowData) ? t('common:unarchive') : t('common:archive')}
        </Button>
        <Button onClick={closeArchiveDialog}>{t('common:cancel')}</Button>
      </div>
    ),
    [closeArchiveDialog, handleArchive, handleArchiveButton, isLoading, t],
  );

  const handleAddDialogFooter = useCallback(
    () => (
      <div className={styles.bottomButton}>
        <Button
          id="dialog-add-button"
          themeColor="primary"
          loading={handleAdd?.isLoading ?? isLoading}
          icon="check"
          onClick={handleAddButton}
        >
          {t('common:save')}
        </Button>
        <Button onClick={closeAddDialog}>{t('common:cancel')}</Button>
      </div>
    ),
    [closeAddDialog, handleAdd?.isLoading, handleAddButton, isLoading, t],
  );

  const handleEditDialogFooter = useCallback(
    () => (
      <div className={styles.bottomButton}>
        <Button
          id="dialog-edit-button"
          themeColor="primary"
          loading={handleEdit?.isLoading ?? isLoading}
          icon="check"
          onClick={handleEditButton}
        >
          {t('common:save')}
        </Button>
        <Button onClick={closeEditDialog}>{t('common:cancel')}</Button>
      </div>
    ),
    [closeEditDialog, handleEdit?.isLoading, handleEditButton, isLoading, t],
  );

  const filteredValue = useMemo(() => filterByGlobalFilter(value, globalFilter), [globalFilter, value]);

  return (
    <>
      {handleAdd && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />}
      <DataTable
        id="reusable-datatable"
        {...rest}
        value={filteredValue}
        header={header}
        paginator={paginator}
        {...(showPagination && {
          paginator: true,
          rows,
          rowsPerPageOptions: [Math.ceil(rows / 2), rows, rows * 2],
          paginatorTemplate: `FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
  RowsPerPageDropdown`,
          currentPageReportTemplate: t('common:showing-first-to-last-of-total-records-items'),
        })}
        emptyMessage={t('common:items-found', { count: 0 })}
      >
        {left}
        {(handleEdit || handleDelete || handleArchive) && (
          <Column
            headerStyle={{ width: '5rem' }}
            bodyStyle={{ padding: 0, textAlign: 'center' }}
            style={{ padding: 0 }}
            body={actionButtonTemplate}
          />
        )}
        {children}
      </DataTable>
      {showDeleteItemDialog && handleDelete && item && (
        <Dialog
          className={styles.deleteDialog}
          visible={showDeleteItemDialog}
          style={{ width: '450px' }}
          header={<span className="capitalize">{handleDelete.title?.(item) || t('common:confirm-delete')}</span>}
          modal
          footer={handleDeleteDialogFooter}
          onHide={closeDeleteDialog}
        >
          <div className={styles.confirmationContent}>
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {handleDelete.itemName ? (
              <span className={styles.text}>{t('common:delete-confirm', { item: item[handleDelete.itemName] })}</span>
            ) : (
              <div>{handleDelete.message?.(item)}</div>
            )}
          </div>
        </Dialog>
      )}
      {showArchiveItemDialog && handleArchive && item && (
        <Dialog
          className={styles.archiveDialog}
          visible={showArchiveItemDialog}
          style={{ width: '450px' }}
          header={
            <span className="capitalize">
              {handleArchive.title?.(item) || handleArchive.isArchived(item)
                ? t('common:confirm-unarchive')
                : t('common:confirm-archive')}
            </span>
          }
          modal
          footer={handleArchiveDialogFooter(item)}
          onHide={closeArchiveDialog}
        >
          <div className={styles.confirmationContent}>
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {handleArchive.itemName ? (
              <span className={styles.text}>
                {handleArchive.isArchived(item)
                  ? t('common:unarchive-confirm', { item: item[handleArchive.itemName] })
                  : t('common:archive-confirm', { item: item[handleArchive.itemName] })}
              </span>
            ) : (
              <div>{handleArchive.message?.(item)}</div>
            )}
          </div>
        </Dialog>
      )}
      {showEditDialog && handleEdit && item && (
        <Dialog
          visible={showEditDialog}
          className={handleEdit.dialogClassName ?? styles.dialog}
          style={handleEdit.dialogStyle}
          header={renderHeader}
          modal
          blockScroll
          footer={handleEditDialogFooter}
          onHide={closeEditDialog}
        >
          {handleEdit.renderForm(item)}
        </Dialog>
      )}
      {showAddDialog && handleAdd && (
        <Dialog
          visible={showAddDialog}
          className={handleAdd.dialogClassName ?? styles.dialog}
          style={handleAdd.dialogStyle}
          header={renderAddHeader}
          modal
          blockScroll
          footer={handleAddDialogFooter}
          onHide={closeAddDialog}
        >
          {handleAdd.renderForm()}
        </Dialog>
      )}
    </>
  );
};

export default CrudDataTable;

CrudDataTable.defaultProps = {
  left: null,
  rows: 10,
  onRefresh: null,
  handleDelete: null,
  handleArchive: null,
  handleEdit: null,
  handleAdd: null,
  title: '',
  showSearch: true,
  showPagination: true,
  paginator: true,
};
