import { lazyImport } from 'utils/lazyImport';

import { AppId, MetrisApp } from './apps.interface';

const { AssistantChat } = lazyImport(() => import('features/sophia/components'), 'AssistantChat');
const { DataAnalyticsRoutes } = lazyImport(() => import('features/dataAnalytics'), 'DataAnalyticsRoutes');
const { WorkPermitRoutes } = lazyImport(() => import('features/workPermit'), 'WorkPermitRoutes');
const { SPMRoutes } = lazyImport(() => import('features/spm'), 'SPMRoutes');
const { DatabaseBackupRoutes } = lazyImport(() => import('features/databaseBackup'), 'DatabaseBackupRoutes');
const { SettingsRoutes } = lazyImport(() => import('features/settings'), 'SettingsRoutes');
const { InspectionRoutes } = lazyImport(() => import('features/inspection'), 'InspectionRoutes');
const { MotorsRoutes } = lazyImport(() => import('features/motors'), 'MotorsRoutes');
const { CPARoutes } = lazyImport(() => import('features/controlPerformanceAssessement'), 'CPARoutes');
const { KanbanRoutes } = lazyImport(() => import('features/kanban'), 'KanbanRoutes');

const { ProductionManagementRoutes } = lazyImport(
  () => import('features/productionManagement'),
  'ProductionManagementRoutes',
);
const { DigitalTwinRoutes } = lazyImport(() => import('features/digitalTwin'), 'DigitalTwinRoutes');
const { AutonomousOperationsRoutes } = lazyImport(
  () => import('features/autonomousOperations'),
  'AutonomousOperationsRoutes',
);
const { DownloadRoutes } = lazyImport(() => import('features/download'), 'DownloadRoutes');
const { PelletingCBMRoutes } = lazyImport(() => import('features/pelletingCBM'), 'PelletingCBMRoutes');
const { CMSRoutes } = lazyImport(() => import('features/cms'), 'CMSRoutes');
export type AppStatus = 'Development' | 'Preview' | 'Productize' | 'Released';

export const apps: MetrisApp[] = [
  {
    id: AppId.Sophia,
    name: (t) => t('common:app-names.sophia-virtual-assistant'),
    image: 'images/icons/App_Sophia.png',
    status: 'Released',
    path: 'sophia',
    element: <AssistantChat />,
  },
  {
    id: AppId.DataAnalytics,
    name: (t) => t('common:app-names.data-analytics'),
    image: 'images/icons/Buttons/LineChart.png',
    status: 'Released',
    path: 'data-analytics',
    element: <DataAnalyticsRoutes />,
  },
  {
    id: AppId.SystemPerformanceMonitor,
    name: (t) => t('common:app-names.system-performance-monitor'),
    image: 'images/icons/APP_SystemPerformanceMonitor.png',
    status: 'Released',
    path: 'system-performance-monitor',
    element: <SPMRoutes />,
  },
  {
    id: AppId.ProductionManagement,
    name: (t) => t('common:app-names.production-management'),
    image: 'images/icons/App_ProductionManagement.png',
    status: 'Development',
    path: 'production-management',
    element: <ProductionManagementRoutes />,
  },
  {
    id: AppId.DigitalTwin,
    name: (t) => t('common:app-names.digital-twin'),
    image: 'images/icons/App_DigitalTwin.png',
    status: 'Development',
    path: 'digital-twin',
    element: <DigitalTwinRoutes />,
  },
  {
    id: AppId.AutonomousOperations,
    name: () => 'Autonomous Operations',
    image: 'images/icons/App_Automation.png',
    status: 'Development',
    path: 'autonomous-operations',
    element: <AutonomousOperationsRoutes />,
  },
  {
    id: AppId.PelletingCBM,
    name: (t) => t('common:app-names.pelleting-cbm'),
    image: 'images/icons/App_ProductionManagement.png',
    status: 'Preview',
    path: 'pelleting-cbm',
    element: <PelletingCBMRoutes />,
    permissions: [{ module: 'module24', value: 1 }],
  },
  {
    id: AppId.CMS,
    name: (t) => t('common:app-names.cms'),
    image: 'images/icons/App_ConditionMonitoringSystem.png',
    status: 'Preview',
    path: 'cms',
    element: <CMSRoutes />,
    permissions: [{ module: 'module30', value: 2 }],
  },
  {
    id: AppId.ControlPerformanceAssessment,
    name: () => 'Control Performance Assessment',
    image: 'images/icons/App_CPA.png',
    status: 'Development',
    path: 'control-performance-assessment',
    element: <CPARoutes />,
  },
  {
    id: AppId.Inspection,
    name: () => 'Inspection',
    image: 'images/icons/Buttons/LineChart.png',
    status: 'Development',
    path: 'inspection',
    element: <InspectionRoutes />,
  },
  {
    id: AppId.Kanban,
    name: (t) => t('common:app-names.kanban'),
    image: 'images/icons/App_Kanban.png',
    status: 'Development',
    path: 'kanban',
    element: <KanbanRoutes />,
  },
  {
    id: AppId.WorkPermit,
    name: (t) => t('common:app-names.work-permit'),
    image: 'images/icons/Buttons/Approval.png',
    status: 'Released',
    path: 'work-permit',
    element: <WorkPermitRoutes />,
  },
  {
    id: AppId.Downloads,
    name: () => 'Downloads',
    image: 'images/icons/App_Download.png',
    status: 'Released',
    path: 'downloads',
    element: <DownloadRoutes />,
  },
  {
    id: AppId.DatabaseBackup,
    name: (t) => t('common:app-names.database-backup'),
    image: 'images/icons/App_LogoNotDefined2.png',
    status: 'Preview',
    path: 'database-backup',
    element: <DatabaseBackupRoutes />,
  },
  {
    id: AppId.Motors,
    name: (t) => t('common:app-names.motors'),
    image: 'images/icons/App_Motors2.png',
    status: 'Development',
    path: 'motors',
    element: <MotorsRoutes />,
  },
  {
    id: AppId.Settings,
    name: (t) => t('common:app-names.settings'),
    image: 'images/icons/Settings.png',
    status: 'Development',
    path: 'settings',
    element: <SettingsRoutes />,
  },
];
