/* eslint-disable @typescript-eslint/no-unused-vars */
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Popup } from '@progress/kendo-react-popup';
import MainWindowTopBackground2 from 'assets/images/MainWindowTopBackground2.png';
import MetrisLogoTransparent from 'assets/images/MetrisLogoTransparent.png';
import userWhiteIcon from 'assets/images/icons/UserWhite.png';
import { avatarSelector } from 'features/auth/reducer/auth';
import { useMediaQuery } from 'hooks';
import { memo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store';

import styles from './Navbar.module.scss';
import { NotificationPopup, UserPopupMenu } from './components';

const Navbar = () => {
  const userAnchor = useRef<HTMLDivElement | null>(null);
  const notificationAnchor = useRef<HTMLDivElement | null>(null);
  const [showUser, setShowUser] = useState(false);
  const { isMobile } = useMediaQuery();
  const [showNotification, setShowNotification] = useState(false);
  const avatar = useAppSelector(avatarSelector);

  const onClickMenu = () => {
    setShowUser((state) => !state);
  };

  const handleCloseUserDialog = () => {
    setShowUser(false);
  };

  const onClickNotification = () => {
    setShowNotification(!showNotification);
  };

  return (
    <header className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Link to="/app" className={styles.logo}>
            <img src={MetrisLogoTransparent} alt="Metris" />
            <h5>Metris</h5>
            <h5>All-in-one Platform</h5>
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <img src={MainWindowTopBackground2} alt="Metris" />
          {/* <a
            className={styles.help}
            href="https://localhost:9000/repo/Home/Help/669617747.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/icons/TopMenuHelp.png" alt="help" />
          </a> */}
          {/* <div
            className={styles.notification}
            tabIndex={0}
            role="button"
            onKeyDown={onClickNotification}
            onClick={onClickNotification}
            ref={notificationAnchor}
          >
            <div className={styles.avatar}>
              <BadgeContainer>
                <img src="/images/icons/TopMenuNotifications.png" alt="notification" />
                <Badge align={{ vertical: 'bottom', horizontal: 'end' }} themeColor="error" size="small" cutoutBorder>
                  1
                </Badge>
              </BadgeContainer>
            </div>
            <Popup
              anchor={notificationAnchor.current}
              show={showNotification}
              popupClass={`${styles.notificationPopup} ${theme === 'dark' ? 'theme--dark' : 'theme--light'}`}
            >
              <NotificationPopup />
            </Popup>
          </div> */}
          <div
            className={styles.user}
            tabIndex={0}
            role="button"
            onKeyDown={onClickMenu}
            onClick={onClickMenu}
            ref={userAnchor}
          >
            <div className={styles.avatar}>
              <img src={avatar || userWhiteIcon} alt="user" />
            </div>
          </div>
          <Popup
            style={isMobile ? { right: 0 } : {}}
            anchor={userAnchor.current}
            show={showUser}
            className={`${styles.popup} ${isMobile ? styles.mobilePopup : ''}`}
          >
            <UserPopupMenu handleClose={handleCloseUserDialog} buttonRef={userAnchor} />
          </Popup>
        </div>
      </div>
    </header>
  );
};

export default memo(Navbar);
