import { Button } from '@progress/kendo-react-buttons';
import { useMediaQuery } from 'hooks';
import { ReactElement, useCallback, useContext } from 'react';

import styles from './ExpandableComponent.module.scss';
import { ExpandableContext } from './ExpandableContext';

interface ExpandableComponentProps {
  children: ReactElement;
  title: string;
  enabled?: boolean;
}

const ExpandableComponent = ({ children, title, enabled = true }: ExpandableComponentProps) => {
  const { isMobile } = useMediaQuery();
  const { zoomKey, setZoomKey } = useContext(ExpandableContext);
  const handleMinimize = useCallback(() => setZoomKey(null), [setZoomKey]);
  const handleMaximize = useCallback(() => setZoomKey(children.key), [children.key, setZoomKey]);

  const isZoom = zoomKey && zoomKey === children.key;

  return (
    <div>
      <div className={styles.topRow}>
        <h2 className={styles.title}>{title}</h2>
        {!isMobile && enabled && (
          <Button
            className={styles.zoomButton}
            icon={isZoom ? 'k-i-window k-i-window-minimize' : 'k-i-window k-i-window-maximize'}
            fillMode="flat"
            size="small"
            onClick={isZoom ? handleMinimize : handleMaximize}
          />
        )}
      </div>
      {children}
    </div>
  );
};

ExpandableComponent.defaultProps = {
  enabled: undefined,
};

export default ExpandableComponent;
