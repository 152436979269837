import { ClickToComponent } from 'click-to-react-component';
import { BusyContext } from 'components/common';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import { BASE_URL } from 'services/api';

import App from './App';
import './i18n';
import { persistor, store } from './store/store';
import './styles/global.scss';

SciChartSurface.configure({
  wasmUrl: `${process.env.PUBLIC_URL}/static/js/scichart2d.wasm`,
  dataUrl: `${process.env.PUBLIC_URL}/scichart2d.data`,
});

SciChartSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCICHART_KEY as string);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  SciChartSurface.setLicenseCallback((queryString: string) => {
    return fetch(`${process.env.REACT_APP_LICENSE_URL}/license/scichart?${queryString}`);
  });
} else {
  SciChartSurface.setLicenseCallback((queryString: string) => {
    return fetch(`${BASE_URL}/license/scichart?${queryString}`);
  });
}

const themes = {
  dark: `${process.env.PUBLIC_URL}/metris-dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/metris-theme.css`,
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ClickToComponent />
    <Provider store={store}>
      <PersistGate loading={<BusyContext isLoading />} persistor={persistor}>
        <ToastContainer style={{ marginTop: 65 }} position="top-right" newestOnTop theme="colored" />
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light" insertionPoint="styles-insertion-point">
          <App />
          <ConfirmPopup />
          <ConfirmDialog draggable={false} />
        </ThemeSwitcherProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
