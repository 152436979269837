/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { Mutex } from 'async-mutex';
import { useCallback, useState } from 'react';

interface DropDownButtonProps<T> {
  id?: string;
  icon: string;
  text: string;
  items: T[];
  itemRender?: (e: any) => JSX.Element;
  onItemClick: (e: DropDownButtonItemClickEvent) => void;
}

const lock = new Mutex();

const DropDownButtonIcon = <T,>({ id, icon, text, items, itemRender, onItemClick }: DropDownButtonProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onButtonClick = useCallback(
    async (e: DropDownButtonItemClickEvent) => {
      const release = await lock.acquire();
      handleClose();
      release();
      onItemClick(e);
    },
    [handleClose, onItemClick],
  );

  return (
    <>
      <DropDownButton
        id={id}
        themeColor="primary"
        fillMode="flat"
        onItemClick={onButtonClick}
        onOpen={handleOpen}
        onClose={handleClose}
        itemRender={itemRender}
        text={
          <>
            {text}
            <span
              className={`p-dropdown-trigger-icon p-clickable pi pi-chevron-${isOpen ? 'up' : 'down'}`}
              style={{ marginLeft: 5, marginTop: 4 }}
            />
          </>
        }
        imageUrl={icon}
        items={items}
        popupSettings={{ animate: { closeDuration: 0, openDuration: 300 } }}
      />
    </>
  );
};
DropDownButtonIcon.defaultProps = {
  id: undefined,
  itemRender: undefined,
};

export default DropDownButtonIcon;
