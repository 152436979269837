import { InputText } from 'primereact/inputtext';
import { useMemo, useRef } from 'react';

import styles from './CodeInput.module.scss';

const modes = ['alpha', 'int', 'alphanum'] as const;
const modeProps = {
  [modes[0]]: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },
  [modes[1]]: { type: 'tel', inputMode: 'numeric', pattern: '[0-9]{1}', min: '0', max: '9' },
  [modes[2]]: { type: 'text', inputMode: 'text', pattern: '[a-zA-Z0-9]{1}' },
} as const;

export interface CodeInputProps {
  mode: typeof modes[number];
  autoFocus?: boolean;
  disabled?: boolean;
  isPassword?: boolean;
  length: number;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const CodeInput = ({ mode, autoFocus, length, disabled, placeholder, onChange }: CodeInputProps) => {
  const valuesRef = useRef<string[]>(Array(length));
  const inputProps = useMemo(() => modeProps[mode], [mode]);

  const sendResult = () => onChange?.(valuesRef.current.join(''));

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value, nextElementSibling } = event.target;
    valuesRef.current[index] = value;

    if (nextElementSibling instanceof HTMLInputElement) {
      nextElementSibling.focus();
    }
    sendResult();
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, target } = event;
    if (key !== 'Backspace') {
      return;
    }
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    const { value, previousElementSibling } = target;
    if (value === '' && previousElementSibling instanceof HTMLInputElement) {
      previousElementSibling.focus();
      previousElementSibling.value = '';
      event.preventDefault();
      valuesRef.current[index - 1] = '';
    } else {
      valuesRef.current[index] = '';
    }
    sendResult();
  };

  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    const pastedValue = event.clipboardData
      .getData('Text')
      .split('')
      .reduce((acc, char) => (char.match(inputProps.pattern) ? acc + char : acc), '')
      .substring(0, length);
    if (pastedValue.length <= 0) {
      return;
    }

    for (let i = 0; i < pastedValue.length; i++) {
      valuesRef.current[i] = pastedValue.charAt(i);
    }

    const { parentNode } = target;
    if (parentNode instanceof HTMLElement) {
      for (let i = 0; i < pastedValue.length; i++) {
        const input = parentNode.children[i];
        if (input instanceof HTMLInputElement) {
          input.value = pastedValue.charAt(i);
        }
      }

      const lastInput = parentNode.children[pastedValue.length - 1];
      if (lastInput instanceof HTMLInputElement) {
        lastInput.focus();
      }
    }
    event.preventDefault();
    sendResult();
  };

  return (
    <fieldset className={styles.container}>
      {[...Array(length).keys()].map((i) => (
        <InputText
          key={i}
          className={styles.input}
          onChange={(e) => handleOnChange(e, i)}
          onKeyDown={(e) => handleOnKeyDown(e, i)}
          onPaste={handleOnPaste}
          {...inputProps}
          maxLength={1}
          keyfilter={mode}
          size={1}
          autoComplete="off"
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={i === 0 && autoFocus}
        />
      ))}
    </fieldset>
  );
};

CodeInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  isPassword: true,
  placeholder: undefined,
  onChange: undefined,
};
