import { DropDownList, DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import brazilFlag from 'assets/images/flags/Brazil.png';
import chinaFlag from 'assets/images/flags/China.png';
import czechRepublicFlag from 'assets/images/flags/CzechRepublic.png';
import denmarkFlag from 'assets/images/flags/Denmark.png';
import finlandFlag from 'assets/images/flags/Finland.png';
import franceFlag from 'assets/images/flags/France.png';
import germanyFlag from 'assets/images/flags/Germany.png';
import hungaryFlag from 'assets/images/flags/Hungary.png';
import indiaFlag from 'assets/images/flags/India.png';
import indonesiaFlag from 'assets/images/flags/Indonesia.png';
import italyFlag from 'assets/images/flags/Italy.png';
import japanFlag from 'assets/images/flags/Japan.png';
import malaysiaFlag from 'assets/images/flags/Malaysia.png';
import netherlandsFlag from 'assets/images/flags/Netherlands.png';
import polandFlag from 'assets/images/flags/Poland.png';
import portugalFlag from 'assets/images/flags/Portugal.png';
import russiaFlag from 'assets/images/flags/Russia.png';
import spainFlag from 'assets/images/flags/Spain.png';
import swedenFlag from 'assets/images/flags/Sweden.png';
import usaFlag from 'assets/images/flags/USA.png';
import { languageSelector, setLanguage } from 'features/main/reducers/settings';
import { Language } from 'features/main/types';
import i18n from 'i18n';
import { cloneElement } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './LanguageSelector.module.scss';

type LanguageObject = {
  id: Language;
  label: string;
  flag: string;
};

type LanguageObjectMap = { [K in Language]: LanguageObject };

const languageObjects: LanguageObjectMap = {
  cs: { id: 'cs', label: 'Čeština (Češko)', flag: czechRepublicFlag },
  da: { id: 'da', label: 'Dansk (Danmark)', flag: denmarkFlag },
  de: { id: 'de', label: 'Deutsch (Deutschland)', flag: germanyFlag },
  en: { id: 'en', label: 'English (United States)', flag: usaFlag },
  es: { id: 'es', label: 'Español (España)', flag: spainFlag },
  fi: { id: 'fi', label: 'Suomi (Suomi)', flag: finlandFlag },
  fr: { id: 'fr', label: 'Français (France)', flag: franceFlag },
  hi: { id: 'hi', label: 'हिन्दी (भारत)', flag: indiaFlag },
  hu: { id: 'hu', label: 'Magyar (Magyarország)', flag: hungaryFlag },
  id: { id: 'id', label: 'Indonesia (Indonesia)', flag: indonesiaFlag },
  it: { id: 'it', label: 'Italiano (Italia)', flag: italyFlag },
  ja: { id: 'ja', label: '日本語 (日本)', flag: japanFlag },
  ms: { id: 'ms', label: 'Melayu (Malaysia)', flag: malaysiaFlag },
  nl: { id: 'nl', label: 'Nederlands (Nederland)', flag: netherlandsFlag },
  pl: { id: 'pl', label: 'Polski (Polska)', flag: polandFlag },
  'pt-BR': { id: 'pt-BR', label: 'Português (Brasil)', flag: brazilFlag },
  'pt-PT': { id: 'pt-PT', label: 'Português (Portugal)', flag: portugalFlag },
  ru: { id: 'ru', label: 'Русский (Россия)', flag: russiaFlag },
  sv: { id: 'sv', label: 'Svenska (Sverige)', flag: swedenFlag },
  zh: { id: 'zh', label: '中文 (中国)', flag: chinaFlag },
};

const languages: LanguageObject[] = Object.values(languageObjects);

const languageRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  const children = [
    <img
      key={2}
      style={{
        height: '1em',
        marginLeft: '0.5em',
      }}
      src={itemProps.dataItem.flag}
      alt={itemProps.dataItem.label}
    />,
    <span key={1}>
      <>&nbsp; {li.props.children}</>
    </span>,
  ];
  return cloneElement(li, li.props, children);
};

const languageValueRender = (element: React.ReactElement<HTMLSpanElement>, value: LanguageObject) => {
  if (!value) {
    return element;
  }

  const children = [
    <span key={1}>
      <>&nbsp; {element.props.children}</>
    </span>,
    <img
      key={2}
      style={{
        height: '1em',
        marginLeft: '0.5em',
      }}
      src={value.flag}
      alt={value.label}
    />,
  ];

  return cloneElement(element, { ...element.props }, children);
};

const LanguageSelector = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(languageSelector);

  const changeLanguage = (event: DropDownListChangeEvent) => {
    const { id } = event.value as LanguageObject;
    dispatch(setLanguage(id));
    i18n.changeLanguage(id);
  };

  return (
    <div className={styles.languageSelector}>
      <DropDownList
        id="change-language"
        style={{ width: '100%' }}
        data={languages}
        textField="label"
        dataItemKey="id"
        fillMode="flat"
        onChange={changeLanguage}
        itemRender={languageRender}
        valueRender={languageValueRender}
        defaultValue={languages.find((l) => l.id === language)}
      />
    </div>
  );
};

export default LanguageSelector;
