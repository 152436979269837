const storagePrefix = 'metris_web';

const storageService = {
  getItem: <T>(key: string) => {
    const value = localStorage.getItem(`${storagePrefix}_${key}`);
    if (value) {
      try {
        const parsedValue = JSON.parse(value) as T;
        return parsedValue;
      } catch {
        return null;
      }
    }
    return null;
  },
  setItem: (key: string, value: unknown) => {
    localStorage.setItem(`${storagePrefix}_${key}`, JSON.stringify(value));
  },
  clearStorage: () => {
    localStorage.clear();
  },
};

export default storageService;
