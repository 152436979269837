import search from 'assets/images/icons/search.png';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Loader } from '../../KendoReact';
import styles from './Card.module.scss';

type CardProps = {
  title?: string;
  onSearch?: (value: string) => void;
  children?: ReactNode;
  isLoading?: boolean;
};

const Card = ({ title, onSearch, children, isLoading }: CardProps) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.container}>
      {title && (
        <>
          <h5 className={styles.title}>{title}</h5>
          <hr />
        </>
      )}
      {onSearch && (
        <div className={styles.search}>
          <Input
            className={styles.searchInput}
            type="search"
            maxLength={50}
            placeholder={t('search')}
            onChange={(e) => onSearch(e.target.value as string)}
          />
          <img className={styles.searchIcon} src={search} alt="search" />
        </div>
      )}
      {isLoading ? <Loader /> : children}
    </div>
  );
};

Card.defaultProps = {
  onSearch: undefined,
  title: '',
  children: null,
  isLoading: undefined,
};

export default Card;
